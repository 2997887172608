import { combineReducers } from "redux";
import offerDetail from "./offerDetailReducer";
import allOffers from "./allOffersReducer";
import submitEvent from "./submitEventReducer";
import offersByCategory from "./offersByCategoryReducer";
import userOrgs from "./userOrgsReducer";
import purchaseTickets from "./purchaseTicketsReducer";
import myOrders from "./myOrdersReducer";
import orderDetails from "./orderDetails";
import organizerDetails from "./organizerDetailsReducer";
import organizerPaymentSetup from "./organizerPaymentSetupReducer";
import selectedOrganization from "./selectedOrganizationReducer";
import organizerPaymentDetails from "./organizerPaymentDetailsReducer";
import validateTicketReducer from "./validateTicketReducer";
import dashboardMetrics from "./dashboardMetricsReducer";
import organizerEvents from "./organizerEventsReducer";
import organizerOrders from "./organizerOrdersReducer";
import organizerRefund from "./organizerRefundReducer";
import createOrganization from "./createOrganizationReducer";
import organizerAdminUsers from "./organizerAdminUsersReducer";
import organizerAdminRoles from "./organizerAdminRolesReducer";
import organizerAdminInvitation from "./organizerAdminInvitationReducer";
import organizerEditEvent from "./organizerEditEventReducer";
import organizerResendTickets from "./organizerResendTickets";
import organizerDuplicateEvent from "./organizerDuplicateEventReducer";
import checkoutEventDetails from "./checkoutEventDetailsReducer";
import organizerEditOrderDetails from "./organizerEditOrderDetailsReducer";
import organizerPayouts from "./organizerPayouts";
import verifyAccesscode from "./verifyAccesscodeReducer";


export default combineReducers({
    offerDetail,
    allOffers,
    offersByCategory,
    submitEvent,
    userOrgs,
    myOrders,
    orderDetails,
    verifyAccesscode,

    checkoutEventDetails,
    purchaseTickets,

    createOrganization,
    organizerEvents,
    organizerEditEvent,
    organizerOrders,
    organizerEditOrderDetails,
    organizerRefund,
    organizerResendTickets,
    organizerDetails,
    organizerPaymentSetup,
    organizerPaymentDetails,
    selectedOrganization,
    organizerAdminUsers,
    organizerAdminRoles,
    organizerAdminInvitation,
    organizerDuplicateEvent,
    organizerPayouts,

    validateTicketReducer,
    dashboardMetrics
})