import React, { useEffect, useState } from 'react'
import { Card, Title, BarChart, Text } from "@tremor/react";


const DailyTicketSales = ({ dataSet, categories }) => {

    const valueFormatter = (number) => Intl.NumberFormat("us").format(number).toString();


    return (
        <div>
            <Card>
                <Title>Ticket Sales Per Day</Title>
                <Text>Ticket sales trends for the last 7 days.</Text>
                <BarChart
                    className="mt-4 h-80 -ml-2"
                    data={dataSet}
                    index="Day"
                    categories={categories}
                    colors={["cyan", "blue", "violet", "fuchsia", "yellow", "cyan"]}
                    valueFormatter={valueFormatter}
                    stack={true}
                />
            </Card>
        </div>
    )
}

export default DailyTicketSales