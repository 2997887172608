// const Loader = () => {
//     return (
//         <div className="flex justify-center items-center w-full h-full">
//             <div className="animate-spin rounded-full h-32 w-32 border-lime-600 border-b-2" />
//         </div>


//     );
// }

// export default Loader;


const Loader = () => {
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-white">
            <div className="animate-spin rounded-full h-32 w-32 border-2 border-lime-600 border-b-transparent" />
        </div>
    );
}

export default Loader;
