// Tabs.jsx
import React from 'react';

export const Tabs = ({ tabs, activeTab, onChange }) => {
  return (
    <div className="m-4 text-sm font-medium text-center text-gray-500 border-b border-gray-200">
      <ul className="flex flex-wrap -mb-px">
        {tabs.map((tab) => (
          <li key={tab.id} className="mr-2">
            <a
              href="#"
              className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 
                ${activeTab === tab.id ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
              onClick={() => onChange(tab.id)}
            >
              {tab.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};