import moment from 'moment-timezone';

export function convertDateToHuman(date) {
    const humanDate = moment(date);
    return humanDate.format("dddd, MMMM Do YYYY");
}



export function convertUnixToHumanDate(unixTimestamp) {
    return moment.unix(unixTimestamp).format('dddd, MMMM D, YYYY');
}

export function formatToShortDateTime(date) {
    /**
     * Converts a date to the format "Dec 29, 3:08 PM"
     * @param {string | Date} date - The date to be formatted
     * @return {string} - The formatted date string
     */
    const formattedDate = moment(date).format("MMM D, h:mm A");
    return formattedDate;
}


export function convertUTCToHumanTime(utcTime) {
    const momentDate = moment(utcTime);
    return moment(momentDate).format('lll');
}


export function convertToHumanDate(date) {
    const humanDate = moment(date);
    return humanDate.format('YYYY-MM-DD HH:mm Z');
}


export function convertToRelativeDate(date) {
    // console.log(date)
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Gets the user's current timezone
    const humanDate = moment(date) // Converts the date to the user's timezone using tz method
    return humanDate.startOf('hour').fromNow(); // Returns the relative time
}