import React, { useState } from 'react';
import StepperControl from '../StepperControl';
import TimePicker from '../MUITimePicker';
import TextEditor from '../TextEditor';
import { validateRequiredText, validateRequiredFile, validateRequiredLongText, validateOptionalText, validateOptionalPhone, validateNone, validateRequiredDate } from '../utils/validators';
// import TimezonePicker from '../TimezonePicker';


const EventInfo = ({ formState, setFormState, currentStep, steps, increaseStep, editMode }) => {

    // const onFileChange = (event) => {
    //     let file = event.target.files[0];
    //     getBase64(file)
    // }

    const onFileLoad = fileString => {
        // console.log(fileString);
        // setForm({ ...form, ['eventImgFile']: { ...form['eventImgFile'], value: fileString, error: null } })
        setFormState({ ...formState, eventInfo: { ...formState.eventInfo, ['eventImgFile']: { ...formState.eventInfo['eventImgFile'], value: fileString, error: null } } })
    }


    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onFileLoad(reader.result)
        }
    }

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            // validate the file type and size
            if (selectedFile.type !== "image/jpeg" && selectedFile.type !== "image/png") {
                setFormState({ ...formState, eventInfo: { ...formState.eventInfo, ['eventImgFile']: { ...formState.eventInfo['eventImgFile'], error: "Please select an image file (jpg or png)" } } })
                // setForm({ ...form, ["eventImgFile"]: { ...form["eventImgFile"], error: "Please select an image file (jpg or png)" } });
            } else if (selectedFile.size > 8388608) {
                // setForm({ ...form, ["eventImgFile"]: { ...form["eventImgFile"], error: "File size cannot exceed 1 MB)" } });
                setFormState({ ...formState, eventInfo: { ...formState.eventInfo, ['eventImgFile']: { ...formState.eventInfo['eventImgFile'], error: "File size cannot exceed 1 MB)" } } })
            } else {
                getBase64(selectedFile)
            }
        } else {
            // setForm({ ...form, ["eventImgFile"]: { ...form["eventImgFile"], error: "Required!" } });
            setFormState({ ...formState, eventInfo: { ...formState.eventInfo, ['eventImgFile']: { ...formState.eventInfo['eventImgFile'], error: "Required!" } } })

        }

    };

    const onUpdateField = e => {
        const name = e.target.name;
        const value = e.target.value;

        // setForm({ ...form, [name]: { ...form[name], value: value, error: null } })
        setFormState({ ...formState, eventInfo: { ...formState.eventInfo, [name]: { ...formState.eventInfo[name], value: value, error: null } } })
    };

    // const onUpdateTimezone = e => {
    //     // console.log(e);
    //     // const { value } = e.target;
    //     console.log(e);

    //     setFormState({ ...formState, eventInfo: { ...formState.eventInfo, ['timezone']: { ...formState.eventInfo['timezone'], value: e, error: null } } })
    // };

    const onUpdateDateTime = (name, time) => {
        if (name && (name === "startDate") || name && (name === "endDate")) {
            // setForm({ ...form, [name]: { ...form[name], value: new Date(time), error: null } })
            setFormState({ ...formState, eventInfo: { ...formState.eventInfo, [name]: { ...formState.eventInfo[name], value: new Date(time), error: null } } })
        } else {
            // setForm({ ...form, [name]: { ...form[name], value: new Date(time), error: null } })
            setFormState({ ...formState, eventInfo: { ...formState.eventInfo, [name]: { ...formState.eventInfo[name], value: new Date(time), error: null } } })
        }

    };

    const onUpdateTextEditor = (name, val) => {
        // setForm({ ...form, [name]: { ...form[name], value: val, error: null } })
        setFormState({ ...formState, eventInfo: { ...formState.eventInfo, [name]: { ...formState.eventInfo[name], value: val, error: null } } })
    }

    const handleTextEditorBlur = (name, val) => {
        // if (!form[name].value) return setForm({ ...form, [name]: { ...form[name], error: "Required!" } });
        if (!formState.eventInfo[name].value) return setFormState({ ...formState, eventInfo: { ...formState.eventInfo, [name]: { ...formState.eventInfo[name], error: "Required!" } } })
    }

    const handleBlur = e => {
        const name = e.target.name;
        const value = e.target.value;
        // if (!form[name].value) return setForm({ ...form, [name]: { ...form[name], error: "Required!" } });
        if (!formState.eventInfo[name].value) return setFormState({ ...formState, eventInfo: { ...formState.eventInfo, [name]: { ...formState.eventInfo[name], error: "Required!" } } })
    };

    const validateField = (field, value) => {
        switch (field) {
            case "eventImgFile":
                return validateRequiredFile(value);
            case "eventName":
                return validateRequiredText(value);
            case "shortDescription":
                return validateRequiredText(value);
            case "category":
                return validateRequiredText(value);
            case "startDate":
                return validateRequiredDate(value);
            case "startTime":
                return validateRequiredDate(value);
            case "endDate":
                return validateRequiredDate(value);
            case "endTime":
                return validateRequiredDate(value);
            case "longDescription":
                return validateRequiredLongText(value);
            default:
                return validateNone();
        }
    }

    const trimForm = (form) => {
        const updatedForm = { ...form };
        const ff = {}
        for (const [name, field] of Object.entries(updatedForm)) {
            ff[name] = field.value
        }
        return ff
    }

    const handleSubmit = (e) => {
        if (e == "prev") {
            increaseStep("prev");
        } else {
            let hasError = false;
            const updatedForm = { ...formState.eventInfo };
            for (const [name, field] of Object.entries(updatedForm)) {
                const error = validateField(name, field.value);
                if (error.msg !== null) {
                    hasError = true;
                    updatedForm[name] = { ...field, error: error.msg };
                }
            }
            if (hasError) {
                // setForm(updatedForm);
                setFormState({ ...formState, eventInfo: updatedForm })
            } else {
                // setFormState({ ...formState, eventInfo: updatedForm })
                increaseStep("next");
            }
        }

        // increaseStep("next");
    };


    return (
        <div
            className='flex min-h-screen h-full mt-16 mx-auto justify-center w-full'
        >
            {/* <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div> */}

            <div
                // className="mt-10 sm:mt-0 w-[832px] max-w-[832px]"
                className={` mt-10 sm:mt-0 px-4 ${!editMode ? '' : 'w-[832px] max-w-[832px]'}`}
            >
                <div className="md:grid md:grid-cols-3 md:gap-6">

                    {
                        !editMode &&

                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">Event Information</h3>
                                <p className="mt-1 text-sm text-gray-600">Provide your organization's detail to create an event.
                                    This organization is your brands Identifier, more than likely the name given during registration with local government offices.</p>
                                <p className="mt-1 text-sm text-gray-600">Customers will see this as the host of your events.</p>
                            </div>
                        </div>
                    }

                    <div
                        // className="mt-5 md:col-span-2 md:mt-0"
                        className={` ${!editMode ? 'mt-5 md:col-span-2 md:mt-0' : 'col-span-3 w-full'}`}
                    >
                        {/* <form action="#" method="POST"> */}
                        <div className="overflow-hidden">
                            <div className="bg-white px-4 py-5 sm:p-6 ">
                                <div className="grid grid-cols-6 gap-6">

                                    {/* upload flyer */}
                                    <div className="col-span-6 sm:col-span-6">
                                        <label class="block text-sm font-medium leading-6 text-gray-900">Cover photo</label>

                                        <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 bg-gray-100">
                                            <div className="space-y-1 text-center">
                                                {formState.eventInfo.eventImgFile.value ? (
                                                    <div className='w-32 mx-auto'>
                                                        <img
                                                            alt="Event img"
                                                            src={formState.eventInfo.eventImgFile.value}
                                                        />
                                                        <br />
                                                        {/* <button onClick={() => deleteFileInput()}>Remove</button> */}
                                                    </div>
                                                ) :
                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                }

                                                <div className="flex text-sm text-gray-600 bg-gray-100">
                                                    <label
                                                        className="relative cursor-pointer mx-auto rounded-md bg-gray-100 font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                    >
                                                        <span>Upload a file</span>
                                                        <input
                                                            // id="eventImgFile"
                                                            name="file-upload"
                                                            type="file"
                                                            className="sr-only"
                                                            accept="image /png, image/jpg, image/gif, image/jpeg"
                                                            onChange={handleFileInputChange}
                                                        />
                                                    </label>
                                                    {/* <p className="pl-1">or drag and drop</p> */}
                                                </div>
                                                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="eventName"
                                            id="eventName"
                                            // className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.eventInfo.eventName.error !== null ? 'border border-red-700' : ''}`}
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.eventInfo.eventName.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.eventInfo.eventName.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.eventInfo.eventName.error}</span>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                            Short Description
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="shortDescription"
                                                name="shortDescription"
                                                rows={3}
                                                className={` px-4 mt-1 block w-full p-1.5 border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6 ${formState.eventInfo.shortDescription.error !== null ? 'border border-red-700' : ''}`}
                                                onChange={onUpdateField}
                                                onBlur={(e) => handleBlur(e)}
                                                value={formState.eventInfo.shortDescription.value}
                                            />
                                            <span className="text-xs text-red-700">{formState.eventInfo.shortDescription.error}</span>
                                        </div>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Brief description for your Organization.
                                        </p>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
                                            Category
                                        </label>
                                        <select
                                            id="category"
                                            name="category"
                                            className={`px-4 mt-2 block w-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.eventInfo.category.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.eventInfo.category.value}
                                        >
                                            <option></option>
                                            <option>Adventure</option>
                                            <option>Business</option>
                                            <option>Food & Drinks</option>
                                            <option>Party</option>
                                            <option>Performing & Visual Arts</option>
                                            <option>Sports</option>
                                        </select>
                                        <span className="text-xs text-red-700">{formState.eventInfo.category.error}</span>
                                    </div>

                                    {/* times */}
                                    <div className="col-span-6 sm:col-span-5 md:col-span-3">
                                        <label htmlFor="start-time" className="block text-sm font-medium leading-6 text-gray-900">
                                            Start Time
                                        </label>
                                        <div className='flex flex-row'>
                                            <TimePicker
                                                name="start"
                                                onChange={onUpdateDateTime}
                                                onBlur={(e) => handleBlur(e)}
                                                form={formState.eventInfo}
                                                editMode={editMode}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-5 md:col-span-3">
                                        <label htmlFor="end-time" className="block text-sm font-medium leading-6 text-gray-900">
                                            End Time
                                        </label>
                                        <div className='flex flex-row'>
                                            <TimePicker
                                                name="end"
                                                onChange={onUpdateDateTime}
                                                // onBlur={(e) => handleBlur(e)}
                                                form={formState.eventInfo}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                                        <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-gray-900">
                                            Timezone
                                        </label>
                                        <div className='flex flex-row w-auto'>
                                            <TimezonePicker
                                                onChange={onUpdateTimezone}
                                                form={formState.eventInfo}
                                            />
                                        </div>
                                    </div> */}

                                    <div className="col-span-6 sm:col-span-6 h-[400px]">
                                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                            Description
                                        </label>
                                        <TextEditor
                                            name="longDescription"
                                            onChange={onUpdateTextEditor}
                                            onBlur={(e) => handleTextEditorBlur(e)}
                                            form={formState.eventInfo}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            !editMode &&
                            <StepperControl
                                handleClick={handleSubmit}
                                currentStep={currentStep}
                                steps={steps}
                            />
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EventInfo