import React from 'react';
import { classNames } from './Utils';

const Input = React.forwardRef(({
    label,
    error,
    helperText,
    required,
    multiline,
    className,
    labelClassName,
    ...props
}, ref) => {
    const inputClasses = `px-4 py-3 mt-1 block w-full border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 leading-8 ${error ? 'border border-red-700' : ''}`;

    const InputElement = multiline ? 'textarea' : 'input';

    return (
        <div className={classNames("flex flex-col", className)}>
            {label && (
                <label className={classNames("text-left block text-sm font-medium leading-6 text-gray-900", labelClassName)}>
                    {label}{required && <span className="text-red-700"> *</span>}
                </label>
            )}
            <div className="mt-2">
                <InputElement
                    ref={ref}
                    className={inputClasses}
                    rows={multiline ? 3 : undefined}
                    {...props}
                />
                {error && <span className="text-xs text-red-700">{error}</span>}
            </div>
            {helperText && (
                <p className="text-left mt-1 text-sm text-gray-500">{helperText}</p>
            )}
        </div>
    );
});

Input.displayName = "Input";

export { Input };