import React, { useState, useMemo, useEffect } from 'react';
import { classNames } from './Utils';

const CountryPicker = ({ onChange, country }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    // const [selectedCountry, setSelectedCountry] = useState(country);

    // Static list of ISO 3166-1 alpha-2 country codes
    const countryCodes = [
        'US', 'JM'
        //'CA', 'GB', 'FR', 'DE', 'JP', 'AU', 'IN', 'BR', 'ZA', 'NG', 'KE', 'CN', 'RU', 'MX', 'IT', 'ES',
        // Add more country codes as needed
    ];

    const countries = useMemo(() => {
        const formatter = new Intl.DisplayNames(['en'], { type: 'region' });
        return countryCodes.map((code) => ({
            value: code,
            label: formatter.of(code),
        })).sort((a, b) => a.label.localeCompare(b.label));
    }, [countryCodes]);

    const filteredCountries = useMemo(() => {
        if (!searchQuery) return countries;
        const query = searchQuery.toLowerCase();
        return countries.filter((country) =>
            country.label.toLowerCase().includes(query) ||
            country.value.toLowerCase().includes(query)
        );
    }, [countries, searchQuery]);

    const handleSelect = (country) => {
        // setSelectedCountry(country.value);
        onChange('country', country.value);
        setIsOpen(false);
        setSearchQuery('');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.country-picker-container')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    const selectedCountryObj = countries.find((c) => c.value === country);

    return (
        <div className="country-picker-container relative w-full text-left">
            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="px-4 mt-2 block w-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 rounded-sm text-left"
            >
                <span className="block truncate">
                    {selectedCountryObj ? selectedCountryObj.label : 'Select country'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </span>
            </button>

            {isOpen && (
                <div className="absolute z-10 mt-1 w-full rounded-sm bg-white shadow-lg">
                    <div className="p-2">
                        <input
                            type="text"
                            className="w-full rounded-sm border-0 py-2 px-3 text-sm leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset text-left"
                            placeholder="Search countries..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <ul className="max-h-60 overflow-auto py-1 text-base sm:text-sm">
                        {filteredCountries.map((countryItem) => (
                            <li
                                key={countryItem.value}
                                className={classNames(
                                    "relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 text-left",
                                    "hover:bg-indigo-600 hover:text-white",
                                    country === countryItem.value ? 'bg-indigo-600 text-white' : ''
                                )}
                                onClick={() => handleSelect(countryItem)}
                            >
                                <span className="block truncate text-sm">
                                    {countryItem.label}
                                </span>
                                {country === countryItem.value && (
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CountryPicker;
