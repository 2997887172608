import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import CreateOrganizerModal from './components/CreateOrganizerModal';


// import reducer from './store/reducers';
import store from './store/store';


import Navbar from './components/Navbar';
import Search from './pages/Search';
import Home from './pages/Home';
import Error from './pages/Error';
import Offer from './pages/Offer';
import Order from './pages/Order';
import CreateEvent from './pages/CreateEvent';
import Footer from './components/Footer';
import OrderDetails from './pages/OrderDetails';
// import Scanner from './components/Scanner';
import OrgEventScan from './pages/OrgEventScan';
import Dashboard from './pages/Dashboard';
import Wrapper from './components/Wrapper';
import OrgWrapper from './components/OrgWrapper';
import Payments from './pages/Payments';
import MyEvents from './pages/MyEvents';
import { OrgOrders } from './pages/OrgOrders';
import EditEvent from './pages/EditEvent';
import OrgSettings from './pages/OrgSettings';
import FAQPage from './pages/FAQPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUs from './pages/ContactUs';
import MyInformation from './pages/MyInformation';
import OrgBasicSettings from './pages/OrgBasicSettings';


function App() {
  return (
    <div className="flex flex-col h-full justify-between">
      <Provider store={store}>
        <BrowserRouter>
          <Navbar />
          <Wrapper>
            <Routes>
              <Route exact path="search" element={<Search />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="events/:offerId" element={<Offer />} />
              <Route exact path="events/:offerId/preview" element={<Offer />} />
              <Route exact path="*" element={<Error />} />
              <Route exact path="/orders" element={<Order />} />
              <Route exact path="/orders/:orderId" element={<OrderDetails />} />
              <Route exact path="/faq" element={<FAQPage />} />
              <Route exact path="/contactus" element={<ContactUs />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route exact path="/about-us" element={<AboutUsPage />} />
              <Route exact path="/settings" element={<MyInformation />} />
              <Route exact path="/create-organization" element={<CreateOrganizerModal />} />
              <Route path='/organizer' element={<OrgWrapper />} >
                <Route exact path=":orgId/create-event" element={<CreateEvent />} />
                <Route exact path=":orgId/events" element={<MyEvents />} />
                <Route exact path=":orgId/events/:offerId/edit" element={<EditEvent />} />
                <Route exact path=":orgId/dashboard" element={<Dashboard />} />
                <Route exact path=":orgId/payments" element={<Payments />} />
                <Route exact path=":orgId/orders" element={<OrgOrders />} />
                <Route exact path=":orgId/events/:offerId/scan" element={<OrgEventScan />} />
                <Route exact path=":orgId/basic-settings" element={<OrgBasicSettings />} />
                <Route exact path=":orgId/team-management" element={<OrgSettings />} />
              </Route>
            </Routes>
          </Wrapper>
          <Footer className="py-10" />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
