// import React, { useState, useEffect } from 'react';

// export function Dropdown({
//     label,
//     options,
//     value,
//     onChange,
//     name,
//     error,
//     placeholder = 'Select an option',
//     labelClassName = "block text-sm font-medium leading-6 text-gray-900",
//     containerClassName = "col-span-6"
// }) {
//     const [isOpen, setIsOpen] = useState(false);

//     // Close dropdown when clicking outside
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (isOpen && !event.target.closest('.dropdown-container')) {
//                 setIsOpen(false);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => document.removeEventListener('mousedown', handleClickOutside);
//     }, [isOpen]);

//     const handleSelect = (selectedValue) => {
//         onChange({ target: { name, value: selectedValue } });
//         setIsOpen(false);
//     };

//     return (
//         <div className={containerClassName}>
//             {label && (
//                 <label className={labelClassName}>
//                     {label}
//                 </label>
//             )}
//             <div className="dropdown-container relative w-full text-left">
//                 <button
//                     type="button"
//                     onClick={() => setIsOpen(!isOpen)}
//                     className="px-4 mt-2 block w-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 rounded-sm text-left"
//                 >
//                     {value || placeholder}
//                     <span className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
//                         <svg
//                             className="h-5 w-5 text-gray-400"
//                             viewBox="0 0 20 20"
//                             fill="currentColor"
//                         >
//                             <path
//                                 fillRule="evenodd"
//                                 d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
//                                 clipRule="evenodd"
//                             />
//                         </svg>
//                     </span>
//                 </button>

//                 {isOpen && (
//                     <ul className="absolute z-10 mt-1 w-full rounded-sm bg-white shadow-lg max-h-60 overflow-auto py-1 text-base sm:text-sm ring-1 ring-gray-300 focus:outline-none">
//                         {options.map((option) => (
//                             <li
//                                 key={option}
//                                 className={`relative cursor-pointer select-none py-2 px-3 text-gray-900 hover:bg-lime-600 hover:text-white ${value === option ? '' : ''
//                                     }`}
//                                 onClick={() => handleSelect(option)}
//                             >
//                                 {option}
//                                 {value === option && (
//                                     <span className="absolute inset-y-0 right-0 flex items-center pr-4">
//                                         <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//                                             <path
//                                                 fillRule="evenodd"
//                                                 d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                                                 clipRule="evenodd"
//                                             />
//                                         </svg>
//                                     </span>
//                                 )}
//                             </li>
//                         ))}
//                     </ul>
//                 )}

//                 {error && (
//                     <p className="mt-2 text-sm text-red-600">
//                         {error}
//                     </p>
//                 )}
//             </div>
//         </div>
//     );
// };



import React, { useState, useEffect } from "react";

export function Dropdown({
    label,
    options,
    value,
    onChange,
    name,
    error,
    placeholder = "Select an option",
    labelClassName = "block text-sm font-medium leading-6 text-gray-900",
    containerClassName = "col-span-6",
    buttonClassName = ""
}) {
    const [isOpen, setIsOpen] = useState(false);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest(".dropdown-container")) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpen]);

    const handleSelect = (selectedOption) => {
        onChange({ target: { name, value: selectedOption.id } });
        setIsOpen(false);
    };

    // Get the selected option object based on value
    const selectedOption = options.find((option) => option.id === value);

    return (
        <div className={containerClassName}>
            {label && <label className={labelClassName}>{label}</label>}

            <div className="dropdown-container relative w-full text-left">
                {/* Selected Item */}
                <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className={` ${buttonClassName} px-4 mt-2 flex items-center justify-between w-full bg-white border-none border-gray-300 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 rounded-sm text-left`}
                >
                    {selectedOption ? (
                        <div className="flex items-center space-x-2">
                            <img
                                className="h-8 w-8"
                                src={selectedOption.img_url || "/default-image.jpg"}
                                alt=""
                            />
                            <span>{selectedOption.title}</span>
                        </div>
                    ) : (
                        <span className="text-gray-400">{placeholder}</span>
                    )}

                    {/* Dropdown Icon */}
                    <span className="pointer-events-none">
                        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </button>

                {/* Dropdown List */}
                {isOpen && (
                    <ul className="absolute z-10 mt-1 w-full rounded-sm bg-white shadow-lg max-h-60 overflow-auto py-1 text-base sm:text-sm ring-1 ring-gray-300 focus:outline-none">
                        {options.map((option) => (
                            <li
                                key={option.id}
                                className="relative flex items-center cursor-pointer select-none py-2 px-3 text-gray-900 hover:bg-lime-600 hover:text-white"
                                onClick={() => handleSelect(option)}
                            >
                                <img className="h-8 w-8 mr-2" src={option.img_url || "/default-image.jpg"} alt="" />
                                {option.title}

                                {value === option.id && (
                                    <span className="absolute inset-y-0 right-4 flex items-center">
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                )}

                {/* Error Message */}
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            </div>
        </div>
    );
}
