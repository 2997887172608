import React, { useState, useEffect, useMemo } from 'react';
import { classNames } from './shared/Utils';

const TimezonePicker = ({ onChange, timezone }) => {
    // Get initial timezone value from form or default to system timezone
    const initialTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timezone;
    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(initialTimezone);

    // Get all timezone names using Intl
    const timezones = useMemo(() => {
        // Get unique timezone names
        const tzNames = new Set();

        // Get all timezones
        const dateFormatter = new Intl.DateTimeFormat();
        const timezonesList = Intl.supportedValuesOf('timeZone');

        timezonesList.forEach(timezone => {
            try {
                // Format the timezone name
                const formatter = new Intl.DateTimeFormat(undefined, {
                    timeZone: timezone,
                    timeZoneName: 'long',
                });
                const timezoneName = formatter.formatToParts()
                    .find(part => part.type === 'timeZoneName')?.value;

                if (timezoneName) {
                    // Store both the IANA name and the formatted name
                    tzNames.add({
                        value: timezone,
                        label: `(GMT${new Date().toLocaleString('en-US', { timeZone: timezone, timeZoneName: 'short' }).split(' ').pop()}) ${timezone.replace(/_/g, ' ')}`
                    });
                }
            } catch (e) {
                console.warn(`Error formatting timezone ${timezone}:`, e);
            }
        });

        return Array.from(tzNames);
    }, []);

    // Filter timezones based on search query
    const filteredTimezones = useMemo(() => {
        if (!searchQuery) return timezones;
        const query = searchQuery.toLowerCase();
        return timezones.filter(tz =>
            tz.label.toLowerCase().includes(query) ||
            tz.value.toLowerCase().includes(query)
        );
    }, [timezones, searchQuery]);

    const handleSelect = (timezone) => {
        setSelectedTimezone(timezone.value);
        onChange('timezone', timezone.value);
        setIsOpen(false);
        setSearchQuery('');
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.timezone-picker-container')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    // Find the currently selected timezone object
    const selectedTimezoneObj = timezones.find(tz => tz.value === selectedTimezone);

    return (
        <div className="timezone-picker-container relative w-full text-left">
            <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="px-4 mt-2 block w-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 rounded-sm text-left"
            >
                <span className="block truncate">
                    {selectedTimezoneObj ? selectedTimezoneObj.label : 'Select timezone'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </span>
            </button>

            {isOpen && (
                <div className="absolute z-10 mt-1 w-full rounded-sm bg-white shadow-lg">
                    <div className="p-2">
                        <input
                            type="text"
                            className="w-full rounded-sm border-0 py-2 px-3 text-sm leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset text-left"
                            placeholder="Search timezones..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <ul className="max-h-60 overflow-auto py-1 text-base sm:text-sm">
                        {filteredTimezones.map((timezone) => (
                            <li
                                key={timezone.value}
                                className={classNames(
                                    "relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 text-left",
                                    "hover:bg-indigo-600 hover:text-white",
                                    selectedTimezone === timezone.value ? 'bg-indigo-600 text-white' : ''
                                )}
                                onClick={() => handleSelect(timezone)}
                            >
                                <span className="block truncate text-sm">
                                    {timezone.label}
                                </span>
                                {selectedTimezone === timezone.value && (
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TimezonePicker;