import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import Table3 from '../components/Table3';
import { BsPersonFillAdd } from 'react-icons/bs';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { requestOrganizationAdminUsers, requestOrganizationAdminRoles } from '../store/actions/index';
import InviteUserModal from '../components/InviteUserModal';

const OrgSettings = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const [sortingState, setSortingState] = useState([]);
    const [selectedTab, setSelectedTab] = useState('Users'); // Default tab is 'Users'

    const dispatch = useDispatch();

    const organizationAdminUsers = useSelector((state) => state.organizerAdminUsers.orgUsers);
    const organizationAdminUsersLoading = useSelector((state) => state.organizerAdminUsers.loading);

    const organizationAdminRoles = useSelector((state) => state.organizerAdminRoles.orgRoles);
    const organizationAdminRolesLoading = useSelector((state) => state.organizerAdminRoles.loading);

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);

    // Define columns for Users
    const orgUsersColumns = [
        {
            accessorKey: 'email',
            header: 'Email',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: (info) => info.getValue(),
        },
        {
            accessorKey: 'roles',
            header: 'Role',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: (info) => info.row.original.roles[0]?.name || '-',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: (info) => info.getValue(),
        },
        {
            accessorKey: 'actions',
            header: 'Actions',
            enableSorting: true,
            meta: {
                align: 'text-right',
            },
            cell: (info) => (
                // <button className="text-blue-500">Edit</button>
                <td class="px-6 py-2 whitespace-nowrap">
                    <button
                        class="inline-flex items-center p-2 text-sm font-medium text-center border-none  text-gray-900 bg-white rounded-lg hover:bg-gray-100 "
                        type="button"
                    // onClick={() => toggleActionMenu(index)}
                    >
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                        </svg>
                    </button>
                </td>
            ),
        },
    ];

    // Define columns for Roles
    const orgRolesColumns = [
        {
            accessorKey: 'name',
            header: 'Name',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: (info) => info.getValue(),
        },
        {
            accessorKey: 'description',
            header: 'Description',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: (info) => info.getValue(),
        },
        {
            accessorKey: 'user_count',
            header: 'Users',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: (info) => info.getValue(),
        },
    ];

    useEffect(() => {
        const loadOrgData = async () => {
            if (loading && organizationDetails?.organizationSettings?.basicSettings?.orgId) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestOrganizationAdminUsers(organizationDetails.organizationSettings.basicSettings.orgId, accessToken));
                dispatch(requestOrganizationAdminRoles(organizationDetails.organizationSettings.basicSettings.orgId, accessToken));
                setLoading(false);
            }
        };

        loadOrgData();
    }, [loading, getAccessTokenSilently, dispatch, organizationDetails]);

    const closeAndResetInvitationModal = () => {
        setShowInviteUserModal(false);
        setLoading(true);
    };

    return (
        <div className="p-4 min-h-screen">
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Settings</h1>
            </div>

            <div className="m-4 text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                <ul className="flex flex-wrap -mb-px">
                    <li className="mr-2">
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${selectedTab === 'Users' ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
                            onClick={() => setSelectedTab('Users')}
                        >
                            Users
                        </a>
                    </li>
                    <li className="mr-2">
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${selectedTab === 'Roles' ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
                            onClick={() => setSelectedTab('Roles')}
                        >
                            Roles
                        </a>
                    </li>
                </ul>
            </div>

            {selectedTab === 'Users' ? (
                !loading && !organizationAdminUsersLoading && (
                    <>
                        <div className="flex justify-between pb-6 items-center space-y-0 flex-row m-4 mt-10">
                            <h1 className="text-xl font-semibold whitespace-nowrap">Users</h1>
                            <div className="w-44">
                                <button
                                    className="flex bg-lime-500 justify-center w-full py-2 rounded-sm text-white hover:bg-lime-600 hover:cursor-pointer border-none text-sm font-semibold"
                                    onClick={() => setShowInviteUserModal(true)}
                                >
                                    <BsPersonFillAdd size={18} className="mr-1" />
                                    Invite new user
                                </button>
                            </div>
                        </div>

                        {organizationAdminUsers.length > 0 && (
                            <div className="flex w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                                <Table3
                                    rows={organizationAdminUsers}
                                    columns={orgUsersColumns}
                                    sortingState={sortingState}
                                    onSortingChange={setSortingState}
                                />
                            </div>
                        )}

                        {showInviteUserModal && (
                            <InviteUserModal onClose={closeAndResetInvitationModal} />
                        )}
                    </>
                )
            ) : (
                !loading && !organizationAdminRolesLoading && (
                    <>
                        <div className="flex flex-col items-start justify-between pb-6 space-y-4 lg:items-center lg:space-y-0 lg:flex-row m-4 mt-10">
                            <h1 className="text-xl font-semibold whitespace-nowrap">Roles</h1>
                        </div>

                        {organizationAdminRoles.length > 0 && (
                            <div className="flex w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                                <Table3
                                    rows={organizationAdminRoles}
                                    columns={orgRolesColumns}
                                    sortingState={sortingState}
                                    onSortingChange={setSortingState}
                                />
                            </div>
                        )}
                    </>
                )
            )}
        </div>
    );
};

export default OrgSettings;
