import React from 'react';
import { Tooltip } from '../../components/shared/Tooltip'
import { Dropdown } from '../../components/shared/Dropdown'
import TimezonePicker from '../TimezonePicker';

const AdvancedSettings = ({ formState, setFormState, editMode }) => {
    const currencies = ['USD', 'JMD'];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Validate access code if that's what changed
        if (name === 'accessCode') {
            const error = validateAccessCode(value);
            setFormState(prev => ({
                ...prev,
                advancedSettings: {
                    ...prev.advancedSettings,
                    [name]: { value, error }
                }
            }));
            return;
        }

        setFormState(prev => ({
            ...prev,
            advancedSettings: {
                ...prev.advancedSettings,
                [name]: {
                    value: type === 'checkbox' ? checked : value,
                    error: null
                }
            }
        }));
    };

    const validateAccessCode = (code) => {
        if (!code) return "Access code is required";
        if (code.length < 4) return "Access code must be at least 4 characters";
        if (!/^[a-zA-Z0-9]+$/.test(code)) return "Access code must be alphanumeric";
        return null;
    };

    const onUpdateTimezone = (name, value) => {
        setFormState(prev => ({
            ...prev,
            advancedSettings: {
                ...prev.advancedSettings,
                timezone: { value, error: null }
            }
        }));
    };

    return (
        <div className='flex min-h-full h-full mt-16 mx-auto justify-center w-full'>
            <div className={`mt-10 sm:mt-0 px-4 ${!editMode ? '' : 'w-[832px] max-w-[832px]'}`}>
                <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                        {console.log(formState.advancedSettings.timezone)}

                        {console.log(formState)}

                        {/* Timezone Selection */}
                        <div className="col-span-6">
                            <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-gray-900">
                                Timezone
                            </label>
                            <div className='flex flex-row w-auto'>
                                <TimezonePicker
                                    onChange={onUpdateTimezone}
                                    timezone={formState.advancedSettings.timezone.value}
                                    className={`px-4 mt-2 block w-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.advancedSettings?.timezone?.error !== null ? 'border border-red-700' : ''}`}
                                />
                            </div>
                            {formState.advancedSettings?.timezone?.error && (
                                <p className="mt-3.5 text-sm text-red-600">
                                    {formState.advancedSettings.timezone.error}
                                </p>
                            )}
                        </div>

                        {/* Currency Selection */}
                        <Dropdown
                            label="Event Currency"
                            options={currencies}
                            value={formState.advancedSettings?.currency?.value}
                            onChange={handleChange}
                            name="currency"
                            error={formState.advancedSettings?.currency?.error}
                            placeholder="Select currency"
                        />



                        {/* Hide Event Checkbox */}
                        <div className="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="hideEvent"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    checked={formState.advancedSettings.hideEvent.value}
                                    onChange={handleChange}
                                />
                                <span className="ml-2 text-gray-700">Hide this event</span>
                                <Tooltip content="This will hide your event from Lymetickets search as well as search engines">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2 text-gray-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                    </svg>
                                </Tooltip>
                            </label>
                        </div>

                        {/* Access Code */}
                        <div className="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="requireAccessCode"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    checked={formState.advancedSettings.requireAccessCode.value}
                                    onChange={handleChange}
                                />
                                <span className="ml-2 text-gray-700">Access code required</span>
                            </label>

                            {formState.advancedSettings?.requireAccessCode?.value && (
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        name="accessCode"
                                        value={formState.advancedSettings.accessCode.value}
                                        onChange={handleChange}
                                        placeholder="Enter access code (alphanumeric only)"
                                        className={`px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${formState.advancedSettings?.accessCode?.error ? 'ring-red-300' : 'ring-gray-300'} placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {formState.advancedSettings?.accessCode?.error && (
                                        <p className="mt-2 text-sm text-red-600">
                                            {formState.advancedSettings.accessCode.error}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Hide Share Buttons */}
                        <div className="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="hideShareButtons"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    checked={formState.advancedSettings.hideShareButtons.value}
                                    onChange={handleChange}
                                />
                                <span className="ml-2 text-gray-700">Hide share buttons on event page</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedSettings;