import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    detailList: {},
    loading: false,
    error: ""
}


export default function offerDetail(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_OFFER_DETAILS: {
            return {
                ...state,
                detailList: {},
                loading: true,
                error: ""
            };
        }
        case actionTypes.RESET_OFFER_DETAILS: {
            return {
                ...state,
                detailList: {},
                loading: false,
                error: ""
            };
        }
        case actionTypes.RECEIVE_OFFER_DETAILS: {
            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        detailList: {},
                        loading: false,
                        error: action.payload
                    };
                }
                default: {
                    return {
                        ...state,
                        detailList: action.payload.data[0] || {},
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
