import React, { useEffect } from 'react';
import Loader from '../Loader'
import { useDispatch, useSelector } from 'react-redux';
import { requestCheckoutEventDetails } from '../../store/actions/index'


const EventSelection = ({
    organizationEvents,
    organizationEventsLoading,
}) => {

    const dispatch = useDispatch();

    function handleEventSelected(event_id) {
        dispatch(requestCheckoutEventDetails(event_id));
    }

    return (
        <div>
            <h2 className="text-2xl font-semibold mb-8 mx-6">Select an Event</h2>
            <div className="events-grid">
                {organizationEventsLoading ? (
                    <Loader />
                ) : (
                    organizationEvents.map((event, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                handleEventSelected(event.id)
                            }}
                            className="event-card p-4 m-2 border rounded-md hover:bg-gray-100 cursor-pointer mx-6"
                        >
                            <h3 className="text-lg font-semibold">{event.title}</h3>
                            <p className="text-sm">{event.short_description}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default EventSelection;
