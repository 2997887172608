// import React from 'react'
// import { classNames } from './Utils'

// export function Button({ children, className, ...rest }) {
//   return (
//     <button
//       type="button"
//       className={
//         classNames(
//           "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
//           className
//         )}
//       {...rest}
//     >
//       {children}
//     </button>
//   )
// }

// export function PageButton({ children, className, ...rest }) {
//   return (
//     <button
//       type="button"
//       className={
//         classNames(
//           "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
//           className
//         )}
//       {...rest}
//     >
//       {children}
//     </button>
//   )
// }

import React from 'react';
import { classNames } from './Utils';

const Spinner = () => (
  <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
  </svg>
);

const variants = {
  primary: 'bg-gray-600 text-white border-gray-600 hover:bg-gray-500 hover:border-gray-500',
  success: 'bg-lime-600 text-white border-lime-600 hover:bg-lime-500 hover:border-lime-500',
  danger: 'bg-red-600 text-white border-red-600 hover:bg-red-500 hover:border-red-500',
  warning: 'bg-yellow-600 text-white border-yellow-600 hover:bg-yellow-500 hover:border-yellow-500',
  info: 'bg-blue-600 text-white border-blue-600 hover:bg-blue-500 hover:border-blue-500',
  light: 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50',
  dark: 'bg-gray-800 text-white border-gray-800 hover:bg-gray-700 hover:border-gray-700'
};

export function Button({
  children,
  className,
  variant = 'primary',
  disabled = false,
  isLoading = false,
  ...rest
}) {
  return (
    <button
      type="button"
      disabled={disabled || isLoading}
      className={classNames(
        'uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer',
        'transition duration-200 ease-in-out',
        'hover:opacity-80',
        'disabled:opacity-30 disabled:bg-slate-700 disabled:border-slate-300',
        'inline-flex items-center justify-center',
        variants[variant],
        className
      )}
      {...rest}
    >
      {isLoading && <Spinner />}
      {children}
    </button>
  );
}