// src/utils/formatCurrency.js

export function getCurrencyFormat(value, currency, isCents = false) {
    if (isCents) {
        value = value / 100;
    }

    const currencyConfig = {
        USD: { symbol: '$', locale: 'en-US' },
        JMD: { symbol: '$', locale: 'en-JM' },
        // Add more currencies as needed
    };

    // Clean and parse the input value
    let numericValue;

    if (typeof value === 'string') {
        // Remove currency symbols, commas, and spaces
        const cleanValue = value.replace(/[$€£¥₹,\s]/g, '');
        numericValue = parseFloat(cleanValue);
    } else if (typeof value === 'number') {
        numericValue = value;
    } else {
        throw new Error('Value must be a number or numeric string');
    }

    // Validate parsed value
    if (isNaN(numericValue)) {
        throw new Error('Invalid numeric value provided');
    }

    // Validate currency
    if (!currency || !currencyConfig[currency]) {
        throw new Error('Invalid or unsupported currency code');
    }

    try {
        // Get configuration for the specified currency
        const config = currencyConfig[currency];

        // Format the number using Intl.NumberFormat
        const formatter = new Intl.NumberFormat(config.locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return `${formatter.format(numericValue)}`;
    } catch (error) {
        return ""
    }
}