import React, { useState, useEffect } from 'react'
import AnonymousReservation from './AnonymousReservation'
import TicketSelection from './TicketSelection'
import EventSelection from '../steps/EventSelection'
import StripeCheckoutComponent from './StripeCheckoutComponent'
import StepperControl from '../StepperControl'
import { useAuth0 } from '@auth0/auth0-react';
import { validateRequiredText, validateRequiredFile, validateRequiredEmail, validateNone } from '../utils/validators';
import { requestPurchaseTickets, resetPurchaseTickets, requestOrganizerEvents, resetCheckoutEventDetails, requestCheckoutEventDetails } from '../../store/actions/index';
import useResetCheckoutModal from '../../hooks/useResetCheckoutModal'

import { useDispatch, useSelector } from 'react-redux';
import OrderSummary from './OrderSummary';
import Loader from '../Loader';
import Login from '../authentication/login';
import LoginForm from './LoginForm'

import axios from "axios";


const CheckoutModal = ({ onClose, organizationMode, showCheckoutModal, eventId }) => {
    const orderDetails = useSelector((state) => state.purchaseTickets.orderDetails);
    const clientSecretLoading = useSelector((state) => state.purchaseTickets.loading);
    const reserveTicketsError = useSelector((state) => state.purchaseTickets.error);

    const organizationEvents = useSelector(state => state.organizerEvents.orgEvents);
    const organizationEventsLoading = useSelector(state => state.organizerEvents.loading);

    const eventDetails = useSelector((state) => state.checkoutEventDetails.eventDetails);
    const eventDetailsLoading = useSelector((state) => state.checkoutEventDetails.loading);
    const dispatch = useDispatch();

    const STEPS = {
        SELECT_EVENT: 'SELECT_EVENT',
        TICKET_SELECTION: 'TICKET_SELECTION',
        TICKET_HOLDER_INFO: 'TICKET_HOLDER_INFO',
        PAYMENT: 'PAYMENT',
        ORDER_SUMMARY: 'ORDER_SUMMARY'
    }
    const { isAuthenticated, user, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [orderId, setOrderId] = useState(null);
    const [currentStep, setCurrentStep] = useState(organizationMode ? STEPS.SELECT_EVENT : STEPS.TICKET_SELECTION);
    const [expiresAt, setExpiresAt] = useState(null);
    const [showStepper, setShowStepper] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [checkoutOffer, setCheckoutOffer] = useState(null);
    const isResetComplete = useResetCheckoutModal(true);
    const [isReady, setIsReady] = useState(false)
    const [ticketState, setTicketState] = useState({
        tickets: {
        },
        ticketHolderInfo: {
            firstName: {
                value: null,
                error: null
            },
            lastName: {
                value: null,
                error: null
            },
            email: {
                value: null,
                error: null
            },
            confirmEmail: {
                value: null,
                error: null
            }
        }
    })

    useEffect(() => {
        if (isResetComplete && showCheckoutModal) {
            resetVariables()
        }
    }, [isResetComplete, showCheckoutModal]);

    const resetVariables = () => {
        console.log("Resetting checkout session...");
        setCheckoutOffer(null)
        setOrderId(null);
        setExpiresAt(null);
        setTimeRemaining(null);
        setCurrentStep(organizationMode ? STEPS.SELECT_EVENT : STEPS.TICKET_SELECTION);
        setIsReady(true)
    };

    // Main condition check to set up the initial state for steps
    useEffect(() => {

        // if reset is done for offers for normal users (orgmode = false)
        if (isReady && !organizationMode && !clientSecretLoading && orderDetails && Object.keys(orderDetails).length === 0 && eventDetails && !eventDetailsLoading) {

            if (Object.keys(eventDetails).length === 0 && getOrderedSteps(organizationMode).indexOf(currentStep) === 0) {
                console.log("reset completed, loading events");
                if (eventId) {
                    dispatch(requestCheckoutEventDetails(eventId));
                }
            } else if (Object.keys(eventDetails).length > 0 && getOrderedSteps(organizationMode).indexOf(currentStep) === 0) {
                //org mode, event loaded not next screen. no button
                console.log("event loaded...");
                setCheckoutOffer(eventDetails);
                setCurrentStep(STEPS.TICKET_SELECTION);
                setShowStepper(true);
            }
        }
        else if (isReady && organizationMode && !clientSecretLoading && orderDetails && Object.keys(orderDetails).length === 0 && eventDetails && !eventDetailsLoading) {
            if (Object.keys(eventDetails).length === 0 && getOrderedSteps(organizationMode).indexOf(currentStep) === 0) { //org mode, everything is reset, hence triggering this condition
                console.log("reset completed...");
                setCheckoutOffer(null);
                setCurrentStep(STEPS.SELECT_EVENT);
                setShowStepper(false);
            } else if (Object.keys(eventDetails).length > 0) {
                //org mode, event loaded not next screen. no button
                setCheckoutOffer(eventDetails);
                setCurrentStep(STEPS.TICKET_SELECTION);
                setShowStepper(true);
            }

        }

    }, [eventDetails, orderDetails, clientSecretLoading, eventDetailsLoading, isReady]);

    useEffect(() => {
        if (checkoutOffer) {
            const convertedTickets = {};
            checkoutOffer.tickets.forEach(ticket => {
                convertedTickets[ticket.ticket_id] = { ticket_id: ticket.ticket_id, title: ticket.title, quantity: 0, unit_price: ticket.price, max_per_person: ticket.max_per_person, min_per_person: ticket.min_per_Person, price: 0.00 };
            });

            setTicketState({ ...ticketState, tickets: convertedTickets, event_id: checkoutOffer.id, subTotal: 0, totalProcessingFee: 0, totalServiceCharge: 0, total: 0, totalTicketCount: 0 });
        }
    }, [checkoutOffer]);

    // Timer management
    useEffect(() => {
        const interval = setInterval(() => {
            const currentEpochTime = Math.floor(Date.now() / 1000);
            const timeDifferenceInSeconds = Math.floor(expiresAt - currentEpochTime);

            if (timeDifferenceInSeconds > 0) {
                setTimeRemaining(timeDifferenceInSeconds)
            } else {
                // todo show time expired screens
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [expiresAt]);

    const getExpiresIn = () => {
        const minutes = Math.floor(timeRemaining / 60);
        const remainingSeconds = timeRemaining % 60;

        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const displayStep = (currentStep) => {
        switch (currentStep) {
            case STEPS.SELECT_EVENT:
                return (
                    <EventSelection
                        organizationEvents={organizationEvents}
                        organizationEventsLoading={organizationEventsLoading}
                    />
                );

            case STEPS.TICKET_SELECTION:
                return (
                    <TicketSelection
                        tickets={checkoutOffer.tickets}
                        ticketState={ticketState}
                        setTicketState={setTicketState}
                        currentStep={currentStep}
                        increaseStep={increaseStep}
                        setIsNextDisabled={setIsNextDisabled}
                        organizationMode={organizationMode}
                    />
                );

            case STEPS.TICKET_HOLDER_INFO:
                return (
                    <AnonymousReservation
                        ticketState={ticketState}
                        setTicketState={setTicketState}
                        currentStep={currentStep}
                        increaseStep={increaseStep}
                        setIsNextDisabled={setIsNextDisabled}
                    />
                );

            case STEPS.PAYMENT:
                return (
                    <StripeCheckoutComponent
                        clientSecret={orderDetails.clientSecret}
                        increaseStep={increaseStep}
                        handleErrorToastMessage={handleErrorToastMessage}
                    />
                );

            case STEPS.ORDER_SUMMARY:
                return <OrderSummary orderId={orderId} />;

            default:
                return <Loader />;
        }
    };

    // Step configurations with display names and order
    const stepConfig = {
        [STEPS.SELECT_EVENT]: {
            displayName: 'Select Event',
            order: 0,
            organizationOnly: true
        },
        [STEPS.TICKET_SELECTION]: {
            displayName: 'Ticket Selection',
            order: 1
        },
        [STEPS.TICKET_HOLDER_INFO]: {
            displayName: 'Ticket Holder Information',
            order: 2
        },
        [STEPS.PAYMENT]: {
            displayName: 'Payment',
            order: 3
        },
        [STEPS.ORDER_SUMMARY]: {
            displayName: 'Order Summary',
            order: 4
        }
    };

    // Get ordered steps based on organization mode
    const getOrderedSteps = (organizationMode) => {
        return Object.entries(stepConfig)
            .filter(([_, config]) => !config.organizationOnly || organizationMode)
            .sort((a, b) => a[1].order - b[1].order)
            .map(([step]) => step);
    };

    // Get step display names
    const getStepDisplayNames = (steps) => {
        return steps.map(step => stepConfig[step].displayName);
    };

    const parseTickets = (tickets) => {
        const flatForm = []
        for (const [fieldName, fieldValue] of Object.entries(tickets)) {
            if (typeof fieldValue === "object") {
                const ff = {}
                for (const [subFieldName, subFieldValue] of Object.entries(fieldValue)) {
                    if (subFieldName == 'quantity' && subFieldValue > 0) {
                        ff['ticket_id'] = fieldValue.ticket_id
                        ff[subFieldName] = subFieldValue
                        flatForm.push(ff);
                    }
                }
            }
        }
        return flatForm
    }


    const trimForm = (form) => {
        const ticketArr = parseTickets(ticketState['tickets'])

        const res = {
            order: {
                event_id: ticketState['event_id'],
                firstName: ticketState.ticketHolderInfo['firstName'].value,
                lastName: ticketState.ticketHolderInfo['lastName'].value,
                email: ticketState.ticketHolderInfo['email'].value,
                tickets: ticketArr
            }
        }

        return res
    };


    // testing something out
    const submitOrderReservationForm = async () => {
        let accessToken = ""
        let tokenType = ""
        if (isAuthenticated) {
            accessToken = await getAccessTokenSilently();
            tokenType = "authenticated"
        } else {
            accessToken = await fetchGuestToken();
            tokenType = "unauthenticated"
        }
        console.log(accessToken);
        dispatch(requestPurchaseTickets(trimForm(ticketState), accessToken, tokenType));
    }

    const fetchGuestToken = async () => {
        try {
            const res = await axios.get(`https://staging-api.lymetickets.com/guest-token`, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            return res.data.data.token;
        } catch (err) {
            console.log("Error caught: ", err.message);
        }
    };

    // Step navigation
    const increaseStep = (direction) => {
        const orderedSteps = getOrderedSteps(organizationMode);
        const currentIndex = orderedSteps.indexOf(currentStep);

        if (direction === "next") {
            if (currentIndex < orderedSteps.length - 1) {
                const nextStep = orderedSteps[currentIndex + 1];

                // Handle special cases when moving to next step
                switch (nextStep) {
                    case STEPS.TICKET_HOLDER_INFO:
                        break;
                    case STEPS.PAYMENT:
                        submitOrderReservationForm()
                        break;
                    case STEPS.ORDER_SUMMARY:
                        setExpiresAt(null)
                        setOrderId(orderDetails.order_id)
                        setShowStepper(false)
                        break;
                }

                setCurrentStep(nextStep);
            }
        } else if (direction === "prev" && currentIndex > 0) {
            if ((currentIndex - 1) === 0) {
                resetVariables();
            }
            setCurrentStep(orderedSteps[currentIndex - 1]);
        }

    };

    const handleErrorToastMessage = (message) => {
        setToastMessage(message)
        handlDisplayError()
    }

    //this handles the point from the order is placed to the completion
    //there is essentially a fork between this and the increase step function
    useEffect(() => {

        if (!clientSecretLoading && orderDetails != undefined && Object.keys(orderDetails).length != 0) {

            if (orderDetails.current_step == 'payment') {
                setExpiresAt(orderDetails.exp)
                setCurrentStep(STEPS.PAYMENT)
                setOrderId(orderDetails.order_id)
                setShowStepper(false)
            }

            //this only captures if the user orders a free ticket
            //doesnt account for purchased tickets
            if (orderDetails.current_step == 'summary') {
                setExpiresAt(null)
                setCurrentStep(STEPS.ORDER_SUMMARY)
                setOrderId(orderDetails.order_id)
                setShowStepper(false)
            }
        }

        if (reserveTicketsError != "") {
            setToastMessage(reserveTicketsError)
            handlDisplayError()
        }

    }, [orderDetails]);


    const closeModal = () => {
        onClose()
    }

    const handlDisplayError = () => {
        setShowToast(true);

        // Hide the toast after 3 seconds
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    };


    return (
        <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75">
            <div className="flex items-center justify-center min-h-screen px-4">
                {/* Modal content */}
                <div className="flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl w-full">
                    <div className="flex flex-auto overflow-hidden">
                        {/* Close Button */}
                        <button onClick={closeModal} type="button" className="absolute border-none left-[96%] top-[1%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 z-30" data-modal-hide="extralarge-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>

                        {/* Left Pane */}
                        <div className={`flex flex-col w-full ${checkoutOffer && getOrderedSteps(organizationMode).indexOf(currentStep) > 0 ? 'sm:w-[70%]' : ''}`}>
                            {/* Modal Header */}
                            <div className="flex items-center justify-center p-5 border-b rounded-t">
                                <div className="flex flex-col items-center">
                                    <h3 className="text-xl font-medium text-gray-900">
                                        {checkoutOffer ? checkoutOffer.title : "Select an event"}
                                    </h3>
                                    {expiresAt ? (
                                        <div className="flex items-center">{getExpiresIn()}</div>
                                    ) : null}
                                </div>
                            </div>

                            {/* Toast Message */}
                            {showToast && (
                                <div className="flex items-center justify-center bg-[#f9b260] text-slate-800 min-h-12 h-12 font-medium">
                                    {toastMessage}
                                </div>
                            )}

                            {/* Main Content */}
                            <div className="inline-flex w-full">
                                <div className="w-full max-h-[512px] h-[512px] px-8 overflow-y-auto pt-1">
                                    {(!clientSecretLoading && !eventDetailsLoading && ((!organizationMode && checkoutOffer) || organizationMode)) ? displayStep(currentStep) : <Loader />}
                                </div>
                            </div>

                            {/* Stepper Footer */}
                            <StepperControl
                                handleClick={increaseStep}
                                currentIndex={getOrderedSteps(organizationMode).indexOf(currentStep)}
                                nextText="Next"
                                isNextDisabled={isNextDisabled}
                                show={showStepper}
                            />
                        </div>

                        {/* Right Pane */}
                        {checkoutOffer && (
                            <div className="w-full bg-gray-100 md:w-[30%] flex-col hidden md:block">
                                <img src={checkoutOffer.img_url} alt="event image" className="w-full object-cover h-36 md:max-w-md" />
                                <div className="space-y-2 p-4">
                                    {ticketState.totalTicketCount > 0 ? (
                                        <>
                                            <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
                                            {Object.keys(ticketState.tickets).map(ticketId => (
                                                ticketState.tickets[ticketId].quantity > 0 && (
                                                    <div className="flex justify-between text-sm" key={ticketId}>
                                                        <span>{ticketState.tickets[ticketId].quantity} x {ticketState.tickets[ticketId].title}</span>
                                                        <span>${ticketState.tickets[ticketId].price}</span>
                                                    </div>
                                                )
                                            ))}
                                            <hr className="my-2" />
                                            {ticketState.subTotal > 0 && (
                                                <div className="flex justify-between text-sm my-auto">
                                                    <span>Subtotal</span>
                                                    <span>${ticketState.subTotal}</span>
                                                </div>
                                            )}
                                            {ticketState.totalProcessingFee > 0 && (
                                                <div className="flex justify-between text-sm my-auto">
                                                    <span>Processing Fee</span>
                                                    <span>${ticketState.totalProcessingFee}</span>
                                                </div>
                                            )}
                                            {ticketState.totalServiceCharge > 0 && (
                                                <>
                                                    <div className="flex justify-between text-sm my-auto">
                                                        <span>Service Charge</span>
                                                        <span>${ticketState.totalServiceCharge}</span>
                                                    </div>
                                                    <hr className="my-2" />
                                                </>
                                            )}
                                            <div className="flex justify-between font-semibold">
                                                <span>Total</span>
                                                <span>${ticketState.total}</span>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutModal
