import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Card } from '../components/shared/Card';
import { Input } from '../components/shared/Input';
import { Button } from '../components/shared/Button';
import { Dropdown } from '../components/shared/Dropdown';
import { Alert } from '../components/shared/Alert';
import { Tabs } from '../components/shared/Tabs';
import { TabPanel } from '../components/shared/TabPanel';
import TimezonePicker from '../components/TimezonePicker';
import CountryPicker from '../components/shared/CountryPicker';
import { requestCreateOrganization, requestOrganizerDetails, resetCreateOrganization } from '../store/actions';


// New component needed for file upload
import { ImageUpload } from '../components/shared/ImageUpload';

const OrgBasicSettings = () => {
    const [activeTab, setActiveTab] = useState('basic');
    const dispatch = useDispatch();
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);
    const updateOrganization = useSelector((state) => state.createOrganization.orgCreation);
    const updateOrganizationError = useSelector((state) => state.createOrganization.error);
    const updateOrganizationLoading = useSelector((state) => state.createOrganization.loading);
    const [hasBasicChanges, setHasBasicChanges] = useState(false);
    const [hasContactChanges, setHasContactChanges] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', variant: 'error' });
    // const wasSubmittingRef = useRef(false);

    const [hasChanges, setHasChanges] = useState({
        basic: false,
        contact: false
    });


    const [formState, setFormState] = useState({
        basic: {
            organizationName: { value: organizationDetails?.organizationSettings?.basicSettings?.name || '', error: null, required: true },
            timezone: { value: organizationDetails?.organizationSettings?.basicSettings?.region?.timezone || '', error: null, required: true },
            logo: { value: organizationDetails?.organizationSettings?.basicSettings?.logo || null, error: null, required: false },
            about: { value: organizationDetails?.organizationSettings?.basicSettings?.about || '', error: null, required: false },
            country: { value: organizationDetails?.organizationSettings?.basicSettings?.region?.country || '', error: null, required: true }
        },
        contact: {
            email: { value: organizationDetails?.organizationSettings?.contactPreferences?.primaryContact?.email, error: null, required: true },
            phone: { value: organizationDetails?.organizationSettings?.contactPreferences?.primaryContact?.phone, error: null, required: true },
            facebook: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.facebook || '', error: null, required: false },
            instagram: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.instagram || '', error: null, required: false },
            tiktok: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.tiktok || '', error: null, required: false },
            twitter: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.twitter || '', error: null, required: false }
        }
    });


    // 1. Initial setup and cleanup on mount
    useEffect(() => {
        // Reset alert state and dispatch any initial actions
        setAlert({ show: false, message: '', variant: 'error' });
        dispatch(resetCreateOrganization());

        // Check for successful save on mount
        const pendingSave = localStorage.getItem('pendingSave');
        if (pendingSave) {
            setAlert({
                show: true,
                message: 'Changes saved successfully',
                variant: 'success',
            });
            localStorage.removeItem('pendingSave');
        }
    }, []);

    // 2. Handle successful organization update
    useEffect(() => {
        if (!updateOrganizationLoading && updateOrganization && isSubmitting) {
            // Show success message immediately after successful update
            setAlert({
                show: true,
                message: 'Changes saved successfully',
                variant: 'success',
            });

            const resetOrganizerDetails = async () => {
                setIsSubmitting(false);
                setHasBasicChanges(false);
                setHasContactChanges(false);
                const accessToken = await getAccessTokenSilently();
                dispatch(requestOrganizerDetails(selectedOrg, accessToken));
            };
            resetOrganizerDetails();
        }
    }, [updateOrganization, updateOrganizationLoading]);

    // 3. Handle organization error
    useEffect(() => {
        if (updateOrganizationError?.errorMessage) {
            setAlert({
                show: true,
                message: updateOrganizationError.errorMessage,
                variant: 'error'
            });
            // Clear pending save state if there's an error
            localStorage.removeItem('pendingSave');
            setIsSubmitting(false);
        }
    }, [updateOrganizationError]);

    // 4. Update form state when organization details change
    useEffect(() => {
        if (organizationDetails) {
            setFormState({
                basic: {
                    organizationName: { value: organizationDetails?.organizationSettings?.basicSettings?.name || '', error: null, required: true },
                    timezone: { value: organizationDetails?.organizationSettings?.basicSettings?.region?.timezone || '', error: null, required: true },
                    logo: { value: organizationDetails?.organizationSettings?.basicSettings?.logo || null, error: null, required: false },
                    about: { value: organizationDetails?.organizationSettings?.basicSettings?.about || '', error: null, required: false },
                    country: { value: organizationDetails?.organizationSettings?.basicSettings?.region?.country || '', error: null, required: true }
                },
                contact: {
                    email: { value: organizationDetails?.organizationSettings?.contactPreferences?.primaryContact?.email || '', error: null, required: true },
                    phone: { value: organizationDetails?.organizationSettings?.contactPreferences?.primaryContact?.phone || '', error: null, required: true },
                    facebook: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.facebook || '', error: null, required: false },
                    instagram: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.instagram || '', error: null, required: false },
                    tiktok: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.tiktok || '', error: null, required: false },
                    twitter: { value: organizationDetails?.organizationSettings?.contactPreferences?.socialMedia?.twitter || '', error: null, required: false }
                }
            });
        }
    }, [organizationDetails]);

    // 5. Track changes in form state
    useEffect(() => {
        const hasBasicChanges = checkBasicChanges();
        const hasContactChanges = checkContactChanges();

        setHasChanges({
            basic: hasBasicChanges,
            contact: hasContactChanges
        });
    }, [formState, organizationDetails]);

    // 6. Track form field changes
    useEffect(() => {
        const basicChanged = Object.keys(formState.basic).some(key =>
            formState.basic[key].value !== organizationDetails?.organizationSettings?.basicSettings?.[key]
        );
        setHasBasicChanges(basicChanged);

        const contactChanged = Object.keys(formState.contact).some(key =>
            formState.contact[key].value !== organizationDetails?.organizationSettings?.contactPreferences?.primaryContact?.[key]
        );
        setHasContactChanges(contactChanged);
    }, [formState, organizationDetails]);


    const renderBasicSettings = () => (
        <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-2">
                <ImageUpload
                    label="Box Office Logo"
                    value={formState.basic.logo.value}
                    onChange={handleImageChange}
                />
            </div>

            <div className="col-span-6 sm:col-span-4">
                <Input
                    label="Organization Name"
                    required
                    name="organizationName"
                    value={formState.basic.organizationName.value}
                    error={formState.basic.organizationName.error}
                    onChange={handleInputChange('basic')}
                />
            </div>

            <div className="col-span-6">
                <Input
                    label="About"
                    multiline
                    name="about"
                    helperText="Brief description for your Organization."
                    value={formState.basic.about.value}
                    onChange={handleInputChange('basic')}
                />
            </div>


            <div className="col-span-6 sm:col-span-3">
                <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                    Timezone<span className="text-red-700"> *</span>
                </label>
                <TimezonePicker
                    timezone={formState.basic.timezone.value}
                    onChange={(name, value) => handleInputChange('basic')({ target: { name, value } })}
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                    Country<span className="text-red-700"> *</span>
                </label>
                <CountryPicker
                    country={formState.basic.country.value}
                    onChange={(name, value) => {
                        setFormState(prev => ({
                            ...prev,
                            basic: {
                                ...prev.basic,
                                country: {
                                    ...prev.basic.country,
                                    value: value,
                                    error: null
                                }
                            }
                        }));
                    }}
                />
            </div>
        </div>
    );

    const renderContactSettings = () => (
        <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
                <Input
                    label="Email"
                    type="email"
                    required
                    name="email"
                    value={formState.contact.email.value}
                    error={formState.contact.email.error}
                    onChange={handleInputChange('contact')}
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <Input
                    label="Phone Number"
                    type="tel"
                    required
                    name="phone"
                    value={formState.contact.phone.value}
                    error={formState.contact.phone.error}
                    onChange={handleInputChange('contact')}
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <Input
                    label="Facebook"
                    name="facebook"
                    value={formState.contact.facebook.value}
                    onChange={handleInputChange('contact')}
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <Input
                    label="Instagram"
                    name="instagram"
                    value={formState.contact.instagram.value}
                    onChange={handleInputChange('contact')}
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <Input
                    label="TikTok"
                    name="tiktok"
                    value={formState.contact.tiktok.value}
                    onChange={handleInputChange('contact')}
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <Input
                    label="X"
                    name="twitter"
                    value={formState.contact.twitter.value}
                    onChange={handleInputChange('contact')}
                />
            </div>
        </div>
    );

    const handleInputChange = (section) => (e) => {
        const { name, value } = e.target;
        setFormState(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [name]: { ...prev[section][name], value, error: null }
            }
        }));
    };

    const handleImageChange = (value) => {
        setFormState(prev => ({
            ...prev,
            basic: {
                ...prev.basic,
                logo: {
                    ...prev.basic.logo,
                    value: value,
                    error: null
                }
            }
        }));
    };

    // Function to check basic settings changes
    const checkBasicChanges = () => {
        const original = organizationDetails?.organizationSettings?.basicSettings;
        if (!original) return false;

        return (
            formState.basic.organizationName.value !== original.name ||
            formState.basic.about.value !== original.about ||
            formState.basic.logo.value !== original.logo ||
            formState.basic.timezone.value !== original.region?.timezone ||
            formState.basic.country.value !== original.region?.country
        );
    };

    // Function to check contact settings changes
    const checkContactChanges = () => {
        const originalContact = organizationDetails?.organizationSettings?.contactPreferences?.primaryContact;
        const originalSocial = organizationDetails?.organizationSettings?.contactPreferences?.socialMedia;
        if (!originalContact || !originalSocial) return false;

        return (
            formState.contact.email.value !== originalContact.email ||
            formState.contact.phone.value !== originalContact.phone ||
            formState.contact.facebook.value !== originalSocial.facebook ||
            formState.contact.instagram.value !== originalSocial.instagram ||
            formState.contact.tiktok.value !== originalSocial.tiktok ||
            formState.contact.twitter.value !== originalSocial.twitter
        );
    };

    const getDirtyFields = (currentState, originalState) => {
        const dirtyFields = {
            organizationSettings: {
                basicSettings: {
                    orgId: organizationDetails?.organizationSettings?.basicSettings?.orgId
                }
            }
        };

        if (activeTab === 'basic' && hasChanges.basic) {
            const basicSettings = {};

            // Basic Settings comparisons
            if (currentState.basic.organizationName.value !== originalState?.organizationSettings?.basicSettings?.name) {
                basicSettings.name = currentState.basic.organizationName.value;
            }

            if (currentState.basic.about.value !== originalState?.organizationSettings?.basicSettings?.about) {
                basicSettings.about = currentState.basic.about.value;
            }

            if (currentState.basic.logo.value !== originalState?.organizationSettings?.basicSettings?.logo) {
                basicSettings.logo = currentState.basic.logo.value;
            }

            // Region fields
            if (currentState.basic.timezone.value !== originalState?.organizationSettings?.basicSettings?.region?.timezone ||
                currentState.basic.country.value !== originalState?.organizationSettings?.basicSettings?.region?.country) {
                basicSettings.region = {};

                if (currentState.basic.timezone.value !== originalState?.organizationSettings?.basicSettings?.region?.timezone) {
                    basicSettings.region.timezone = currentState.basic.timezone.value;
                }

                if (currentState.basic.country.value !== originalState?.organizationSettings?.basicSettings?.region?.country) {
                    basicSettings.region.country = currentState.basic.country.value;
                }
            }

            if (Object.keys(basicSettings).length > 0) {
                dirtyFields.organizationSettings.basicSettings = {
                    ...dirtyFields.organizationSettings.basicSettings,
                    ...basicSettings
                };
            }
        }

        if (activeTab === 'contact' && hasChanges.contact) {
            const contactPreferences = {
                primaryContact: {},
                socialMedia: {}
            };

            // Contact Preferences comparisons
            if (currentState.contact.email.value !== originalState?.organizationSettings?.contactPreferences?.primaryContact?.email) {
                contactPreferences.primaryContact.email = currentState.contact.email.value;
            }

            if (currentState.contact.phone.value !== originalState?.organizationSettings?.contactPreferences?.primaryContact?.phone) {
                contactPreferences.primaryContact.phone = currentState.contact.phone.value;
            }

            // Social Media
            if (currentState.contact.facebook.value !== originalState?.organizationSettings?.contactPreferences?.socialMedia?.facebook) {
                contactPreferences.socialMedia.facebook = currentState.contact.facebook.value;
            }

            if (currentState.contact.instagram.value !== originalState?.organizationSettings?.contactPreferences?.socialMedia?.instagram) {
                contactPreferences.socialMedia.instagram = currentState.contact.instagram.value;
            }

            if (currentState.contact.tiktok.value !== originalState?.organizationSettings?.contactPreferences?.socialMedia?.tiktok) {
                contactPreferences.socialMedia.tiktok = currentState.contact.tiktok.value;
            }

            if (currentState.contact.twitter.value !== originalState?.organizationSettings?.contactPreferences?.socialMedia?.twitter) {
                contactPreferences.socialMedia.twitter = currentState.contact.twitter.value;
            }

            if (Object.keys(contactPreferences.primaryContact).length > 0 ||
                Object.keys(contactPreferences.socialMedia).length > 0) {
                dirtyFields.organizationSettings.contactPreferences = contactPreferences;
            }
        }

        // Return null if no actual changes
        if (Object.keys(dirtyFields.organizationSettings).length === 1 &&
            Object.keys(dirtyFields.organizationSettings.basicSettings).length === 1) {
            return null;
        }

        return dirtyFields;
    };

    const handleSave = async () => {
        const dirtyFields = getDirtyFields(formState, organizationDetails);

        if (!dirtyFields) {
            setAlert({
                show: true,
                message: 'No changes detected',
                variant: 'warning',
            });
            return;
        }

        setIsSubmitting(true);
        const accessToken = await getAccessTokenSilently();
        dispatch(requestCreateOrganization(dirtyFields, accessToken));
    };


    return (
        <div className="p-4 min-h-screen">

            {alert.show && (
                <div className="m-4">
                    <Alert
                        variant={alert.variant}
                        message={alert.message}
                        onClose={() => setAlert({ ...alert, show: false })}
                    />
                </div>
            )}

            <div className="flex flex-col items-start justify-between pb-6 space-y-4 lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Settings</h1>
            </div>

            <div className="m-4 text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                <div className="w-full">
                    <Tabs
                        tabs={[
                            { id: 'basic', label: 'Basic Settings' },
                            { id: 'contact', label: 'Contact Preferences' }
                        ]}
                        activeTab={activeTab}
                        onChange={setActiveTab}
                    />
                </div>
                <div className="flex w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                    <div className="w-full lg:mx-48">

                        <TabPanel active={activeTab === 'basic'}>
                            {renderBasicSettings()}
                            <div className="mt-6 flex justify-end">
                                <Button
                                    variant={hasChanges.basic ? "success" : "primary"}
                                    isLoading={isSubmitting}
                                    disabled={!hasChanges.basic || isSubmitting}
                                    onClick={handleSave}
                                >
                                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                                </Button>
                            </div>
                        </TabPanel>

                        <TabPanel active={activeTab === 'contact'}>
                            {renderContactSettings()}
                            <div className="mt-6 flex justify-end">
                                <Button
                                    variant={hasChanges.contact ? "success" : "primary"}
                                    isLoading={isSubmitting}
                                    disabled={!hasChanges.contact || isSubmitting}
                                    onClick={handleSave}
                                >
                                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                                </Button>
                            </div>
                        </TabPanel>

                    </div>
                </div>
            </div>
        </div>


    );
};

export default OrgBasicSettings;
