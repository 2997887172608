import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table5 from '../components/Table5';
import Loader from '../components/Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { requestOrganizerOrders } from '../store/actions/index';
import RefundModal from '../components/RefundModal';
import CheckoutModal from '../components/offer/CheckoutModal';


export const OrgOrders = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [orderSelected, setOrderSelected] = useState({});
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    // const [selectedStatus, setSelectedStatus] = useState(''); // For status filtering
    const dispatch = useDispatch();

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const organizationOrders = useSelector((state) => state.organizerOrders.orgOrders);
    const organizationOrdersLoading = useSelector((state) => state.organizerOrders.loading);

    useEffect(() => {
        const loadOrgOrders = async () => {
            if (loading && organizationDetails && organizationDetails.organizationSettings.basicSettings.orgId) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestOrganizerOrders(organizationDetails.organizationSettings.basicSettings.orgId, accessToken));
                setLoading(false);
            }
        };

        loadOrgOrders();
    }, [loading, organizationDetails, dispatch, getAccessTokenSilently]);

    useEffect(() => {
        if (Object.keys(orderSelected).length !== 0) {
            document.body.classList.add('body-no-scroll');
            setShowRefundModal(true);
        }
    }, [orderSelected]);

    const closeAndResetSelectedOrder = (reset) => {
        if (reset === true) {
            document.body.classList.remove('body-no-scroll');
            setShowRefundModal(false);
            handleShowCreateOrderModal(false)
            setLoading(true);
            setOrderSelected({});
        } else {
            document.body.classList.remove('body-no-scroll');
            setShowRefundModal(false);
            handleShowCreateOrderModal(false)
            setOrderSelected({});
        }

    };

    function handleShowCreateOrderModal(state) {
        setShowCheckoutModal(state)
        if (state === true) {
            document.body.classList.add('body-no-scroll');
        } else {
            document.body.classList.remove('body-no-scroll');
        }

    }

    // Filter orders by status
    // const filteredOrders = organizationOrders.filter(order => {
    //     if (selectedStatus === '') return true; // No filter applied, show all
    //     return order.order_status.toLowerCase() === selectedStatus.toLowerCase();
    // });

    return (
        <div className="p-4 min-h-screen">
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Orders</h1>
                <div className="w-44">
                    <div
                        onClick={() => handleShowCreateOrderModal(true)}
                        className='flex w-full py-3 bg-[#83c342d5] border-none justify-center my-4 text-white text-sm font-semibold leading-tight rounded shadow-md hover:opacity-90 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:opacity-90 active:shadow-lg transition duration-150 ease-in-out'>
                        Create Order
                    </div>
                </div>
            </div>

            {!loading && !organizationOrdersLoading ? (
                <>
                    {organizationOrders.length > 0 ? (
                        <div className="flex justify-center w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                            {/* Table5 with filtering */}
                            <Table5
                                rows={organizationOrders ? organizationOrders : []}
                                orderSelected={setOrderSelected}
                            />
                        </div>
                    ) : (
                        <div className="flex h-[45rem]">
                            <div className="m-auto">
                                <div className="flex flex-col items-center text-slate-500">
                                    <span className="font-semibold text-2xl mb-4">No orders found</span>
                                    <span>You don't have any orders, once they are placed, you will see them here</span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <Loader />
            )}

            {showRefundModal && (
                <RefundModal
                    orderDetails={orderSelected}
                    onClose={(reset) => closeAndResetSelectedOrder(reset)}
                />
            )}

            {showCheckoutModal && <CheckoutModal
                organizationMode={true}
                showCheckoutModal={showCheckoutModal}
                onClose={(reset) => closeAndResetSelectedOrder(reset)}
            />}
        </div>
    );
};
