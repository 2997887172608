export function Tooltip({ children, content }) {
    return (
        <div className="relative inline-block">
            <div className="group">
                {children}
                <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity absolute z-50 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm -translate-x-1/2 -translate-y-full left-1/2 bottom-full mb-2 w-max max-w-xs">
                    {content}
                    {/* Arrow pointing down */}
                    <div className="absolute z-50 w-2 h-2 bg-gray-900 rotate-45 translate-x-1/2 top-full -mt-1 left-1/2"></div>
                </div>
            </div>
        </div>
    )
}