import React, { useEffect, useState, useRef } from 'react';
import StepperControl from '../StepperControl';
import TimePicker from '../MUITimePicker';
import { validateRequiredText, validateOptionalText, validateNone, validateRequiredNumber, validateRequiredDate } from '../utils/validators';
import emptyState from '../../images/empty_state.png';
import { convertDateToHuman } from '../../components/common/dateUtils';
import { getCurrencyFormat } from '../../components/common/currencyUtils';

const INITIAL_TICKET_STATE = {
    title: { value: '', error: null },
    description: { value: '', error: null },
    quantity: { value: '', error: null },
    min: { value: '', error: null },
    max: { value: '', error: null },
    unitPrice: { value: '', error: null },
    currency: { value: '', error: null },
    startDate: { value: new Date(), error: null },
    endDate: { value: new Date(), error: null },
    startTime: { value: new Date(), error: null },
    endTime: { value: new Date(), error: null },
    hasPrice: { value: false, error: null },
    status: { value: 'ON_SALE', error: null },
    quantity_sold: { value: '', error: null },
};

const STATUS_OPTIONS = [
    { value: 'ON_SALE', label: 'On Sale' },
    { value: 'SOLD_OUT', label: 'Sold Out' },
    { value: 'UNAVAILABLE', label: 'Unavailable' },
    { value: 'HIDDEN', label: 'Hidden' },
    { value: 'ADMIN_ONLY', label: 'Admin Only' },
    { value: 'LOCKED', label: 'Locked' },
];

const CURRENCY_OPTIONS = [
    { value: 'USD', label: 'USD' },
    { value: 'JMD', label: 'JMD' },
];

// Add this custom hook at the top of your file
const useClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};

const CurrencyInput = ({ label, name, value, error, onChange, onBlur, disabled, className }) => {
    const formatCurrency = (val) => {
        // Handle empty or invalid input
        if (!val || val === '' || val === '.') return '';

        try {
            // Remove all non-numeric characters except decimal point
            let value = val.replace(/[^\d.]/g, '');

            // Handle multiple decimal points
            const parts = value.split('.');
            if (parts.length > 2) {
                value = `${parts[0]}.${parts[1] || ''}`;
            }

            // Format to 2 decimal places if there's a decimal point
            if (parts.length === 2) {
                // Ensure we don't return NaN
                const wholeNumber = parts[0] || '0';
                const decimal = parts[1].slice(0, 2);
                return `${wholeNumber}.${decimal}`;
            }

            return value;
        } catch (error) {
            console.error('Error formatting currency:', error);
            return '0.00'; // Safe fallback
        }
    };

    const handleChange = (e) => {
        const rawValue = e.target.value;
        const formattedValue = formatCurrency(rawValue);

        onChange({
            target: {
                name: name,
                value: formattedValue,
                type: 'currency'
            }
        });
    };

    const handleBlur = (e) => {
        try {
            let formattedValue = e.target.value;

            // Handle empty value
            if (!formattedValue || formattedValue === '') {
                formattedValue = '0.00';
            } else {
                // Convert to number and back to string to handle any remaining invalid formats
                const numValue = parseFloat(formattedValue);
                if (isNaN(numValue)) {
                    formattedValue = '0.00';
                } else {
                    formattedValue = numValue.toFixed(2);
                }
            }

            onBlur({
                target: {
                    name: name,
                    value: formattedValue,
                    type: 'currency'
                }
            });
        } catch (error) {
            console.error('Error handling blur:', error);
            onBlur({
                target: {
                    name: name,
                    value: '0.00',
                    type: 'currency'
                }
            });
        }
    };

    // Ensure displayed value is always valid
    const displayValue = value === '' || isNaN(value) ? '' : value;

    return (
        <div className={className}>
            <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <div className="relative mt-2">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                    type="text"
                    name={name}
                    id={name}
                    disabled={disabled}
                    className={`pl-7 pr-12 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${error ? 'border-red-500' : ''}`}
                    value={displayValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="0.00"
                    inputMode="decimal"
                />
            </div>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        </div>
    );
};

// Form Input Component
const FormInput = ({ label, name, type, value, error, onChange, onBlur, disabled, className, ...props }) => (
    <div className={className}>
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
            {label}
        </label>
        <input
            type={type}
            name={name}
            id={name}
            disabled={disabled}
            className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${error ? 'border border-red-700' : ''}`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            {...props}
        />
        <span className="text-xs text-red-700">{error}</span>
    </div>
);

// Form Select Component
const FormSelect = ({ label, name, options, value, error, onChange, onBlur, disabled, className }) => {
    // Function to get background color based on value
    const getStatusColor = (currentValue) => {
        switch (currentValue) {
            case 'ON_SALE':
                return 'bg-green-100 text-green-800';
            case 'SOLD_OUT':
                return 'bg-red-100 text-red-800';
            case 'UNAVAILABLE':
                return 'bg-gray-100 text-gray-800';
            case 'HIDDEN':
                return 'bg-purple-100 text-purple-800';
            case 'ADMIN_ONLY':
                return 'bg-blue-100 text-blue-800';
            case 'LOCKED':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-gray-50 text-gray-700';
        }
    };

    return (
        <div className={className}>
            <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <div className="relative mt-2">
                <select
                    id={name}
                    name={name}
                    disabled={disabled}
                    className={`
              appearance-none
              w-full
              px-4
              py-2
              rounded-full
              border-2
              border-transparent
              ${getStatusColor(value)}
              font-medium
              text-sm
              focus:outline-none
              focus:border-blue-500
              transition-colors
              duration-200
              cursor-pointer
              ${disabled ? 'opacity-60 cursor-not-allowed' : ''}
              ${error ? 'border-red-500' : ''}
            `}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ''}
                >
                    {options.map(option => (
                        <option
                            key={option.value}
                            value={option.value}
                            className="bg-white text-gray-900 py-2"
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                    <svg
                        className="h-4 w-4 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </div>
            </div>
            {error && <span className="text-xs text-red-700 mt-1">{error}</span>}
        </div>
    );
};

// Ticket Form Component
const TicketForm = ({ ticket, index, onUpdateField, onUpdateDateTime, handleBlur, handleSaveTicket, handleCheckboxChange, handleUnitPriceChange, editMode, isNewTicket }) => {
    if (!ticket) return null;

    return (
        <div className="mt-5 md:col-span-2 md:mt-0">
            <div className='flex flex-row justify-end mr-10'>
                <FormSelect
                    name="status"
                    options={STATUS_OPTIONS}
                    value={ticket.status.value}
                    error={ticket.status.error}
                    onChange={onUpdateField}
                    onBlur={handleBlur}
                    className="col-span-6 sm:col-span-3 lg:col-span-2"
                />
            </div>


            <div className="overflow-hidden">
                <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                        <FormInput
                            label="Ticket Name"
                            name="title"
                            type="text"
                            value={ticket.title.value}
                            error={ticket.title.error}
                            onChange={onUpdateField}
                            onBlur={handleBlur}
                            disabled={editMode && !isNewTicket}
                            className="col-span-6 sm:col-span-4 lg:col-span-6"
                        />


                        {/* Description */}
                        <div className="col-span-6 sm:col-span-5 lg:col-span-6">
                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={3}
                                    disabled={editMode && !isNewTicket}
                                    className={`px-2 mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6 ${ticket.description.error ? 'border border-red-700' : ''}`}
                                    placeholder="This ticket guarantees you entry into the event"
                                    onChange={onUpdateField}
                                    onBlur={handleBlur}
                                    value={ticket.description.value}
                                />
                                <span className="text-xs text-red-700">{ticket.description.error}</span>
                            </div>
                        </div>

                        {/* Quantity */}
                        <FormInput
                            label="Total Quantity"
                            name="quantity"
                            type="number"
                            min={1}
                            max={100000}
                            value={ticket.quantity.value}
                            error={ticket.quantity.error}
                            onChange={onUpdateField}
                            onBlur={handleBlur}
                            className="col-span-6 sm:col-span-6 lg:col-span-3"
                        />

                        <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                            <label htmlFor="min" className="block text-sm font-medium leading-6 text-gray-900">
                                Tickets per order
                            </label>
                            <div className="flex gap-4 items-center">
                                <FormInput
                                    name="min"
                                    type="number"
                                    min={1}
                                    max={10}
                                    value={ticket.min.value}
                                    error={ticket.min.error}
                                    onChange={onUpdateField}
                                    onBlur={handleBlur}
                                    className="flex-1"
                                />
                                <span className="text-gray-500">-</span>
                                <FormInput
                                    name="max"
                                    type="number"
                                    min={1}
                                    max={10}
                                    value={ticket.max.value}
                                    error={ticket.max.error}
                                    onChange={onUpdateField}
                                    onBlur={handleBlur}
                                    className="flex-1"
                                />
                            </div>
                            {(ticket.min.error || ticket.max.error) && (
                                <span className="text-xs text-red-700 mt-1">
                                    {ticket.min.error || ticket.max.error}
                                </span>
                            )}
                        </div>

                        {/* Time Pickers */}
                        <div className="col-span-6 sm:col-span-5 lg:col-span-3">
                            <TimePicker
                                name="start"
                                onChange={onUpdateDateTime}
                                form={ticket}
                                editMode={editMode && !isNewTicket}
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-5 lg:col-span-3">
                            <TimePicker
                                name="end"
                                onChange={onUpdateDateTime}
                                form={ticket}
                            />
                        </div>

                        {/* Price Settings */}
                        <div className="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="hasPrice"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    disabled={editMode && !isNewTicket}
                                    checked={ticket.hasPrice.value}
                                    onChange={handleCheckboxChange}
                                    value={ticket.hasPrice.value}
                                />
                                <span className="ml-2 text-gray-700">Set ticket price</span>
                            </label>
                        </div>

                        {ticket.hasPrice.value && (
                            <>
                                {/* <FormInput
                                    label="Unit Price"
                                    name="unitPrice"
                                    type="currency"
                                    value={ticket.unitPrice.value}
                                    error={ticket.unitPrice.error}
                                    onChange={handleUnitPriceChange}
                                    onBlur={handleBlur}
                                    disabled={editMode && !isNewTicket}
                                    className="col-span-6 sm:col-span-6 lg:col-span-3"
                                /> */}
                                <CurrencyInput
                                    label="Unit Price"
                                    name="unitPrice"
                                    value={ticket.unitPrice.value}
                                    error={ticket.unitPrice.error}
                                    onChange={handleUnitPriceChange}
                                    onBlur={handleBlur}
                                    disabled={editMode && !isNewTicket}
                                    className="col-span-6 sm:col-span-6 lg:col-span-3"
                                />

                                <FormSelect
                                    label="Currency"
                                    name="currency"
                                    options={CURRENCY_OPTIONS}
                                    value={ticket.currency.value}
                                    error={ticket.currency.error}
                                    onChange={onUpdateField}
                                    onBlur={handleBlur}
                                    disabled={editMode && !isNewTicket}
                                    className="col-span-6 sm:col-span-3 lg:col-span-2"
                                />
                            </>
                        )}
                    </div>
                </div>

                <div className="flex flex-col items-center my-6 mx-auto">
                    <button
                        onClick={() => handleSaveTicket(index)}
                        className="bg-[#529535] text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-[#529535] hover:bg-[#529535] hover:text-white hover:opacity-80 transition duration-200 ease-in-out h-auto w-fit"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

// Modal Component
const Modal = ({ onClose, children }) => (
    <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75">
        <div className="flex items-center justify-center min-h-screen px-4">
            <div className="flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl w-full">
                <button
                    onClick={onClose}
                    type="button"
                    className="absolute border-none left-[94%] top-[2%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 z-30"
                >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
                <div className="flex-col w-full">
                    <div className="inline-flex w-full">
                        <div className="w-full min-h-[512px] px-8 overflow-y-auto pt-1">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

// Main Component
const TicketInfo = ({ formState, setFormState, currentStep, steps, increaseStep, editMode }) => {
    const [submit, setSubmit] = useState(false);
    const [ticketsInfo, setTicketsInfo] = useState(formState.ticketInfo);
    const [tickets, setTickets] = useState(
        formState.ticketInfo.map((ticket) => ({
            ...INITIAL_TICKET_STATE,  // This is overwriting properties
            ...Object.keys(ticket).reduce((acc, key) => ({
                ...acc,
                [key]: { value: ticket[key], error: null }
            }), {}),
            startDate: { value: new Date(ticket.startTime), error: null },
            endDate: { value: new Date(ticket.endTime), error: null },
            startTime: { value: new Date(ticket.startTime), error: null },
            endTime: { value: new Date(ticket.endTime), error: null },
        }))
    );
    const [showTicketForm, setShowTicketForm] = useState(false);
    const [isNewTicket, setIsNewTicket] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);

    const [originalTickets, setOriginalTickets] = useState([]); // Store original ticket data
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        // Initialize original tickets when component mounts
        setOriginalTickets(formState.ticketInfo);
    }, []);

    const canDeleteTicket = (ticket) => {
        // New tickets (no ticketId) can always be deleted
        if (!ticket.ticketId) return true;

        // Existing tickets can only be deleted if no sales
        return parseInt(ticket.quantity_sold || 0) === 0;
    };

    useEffect(() => {
        if (submit) {
            increaseStep("next");
        }
    }, [submit]);

    useEffect(() => {
        setFormState({ ...formState, ticketInfo: [...ticketsInfo] });
    }, [ticketsInfo]);


    const onUpdateField = e => {
        const { name, value } = e.target;
        setTickets(prev => ({
            ...prev,
            [openIndex]: {
                ...prev[openIndex],
                [name]: { value, error: null }
            }
        }));
    };

    const onUpdateDateTime = (name, time) => {
        setTickets(prev => ({
            ...prev,
            [openIndex]: {
                ...prev[openIndex],
                [name]: { value: new Date(time), error: null }
            }
        }));
    };

    const handleBlur = (e) => {
        const { value, name, type } = e.target;
        if (!isNaN(value) && type === 'currency') {
            const formattedValue = parseFloat(value).toFixed(2);
            setTickets(prev => ({
                ...prev,
                [openIndex]: {
                    ...prev[openIndex],
                    [name]: { value: formattedValue, error: null }
                }
            }));
        } else {
            setTickets(prev => ({
                ...prev,
                [openIndex]: {
                    ...prev[openIndex],
                    [name]: { value, error: null }
                }
            }));
        }
    };

    const validateField = (field, value, index) => {
        const updatedTickets = { ...tickets[index] };
        switch (field) {
            case "title":
                return validateRequiredText(value);
            case "description":
                return validateOptionalText(value);
            case "quantity":
                return validateRequiredNumber(value, 0, 10000);
            case "min":
                return validateRequiredNumber(value, 1, 10);
            case "max":
                return validateRequiredNumber(value, 1, 10);
            case "startDate":
                return validateRequiredDate(value);
            case "startTime":
                return validateRequiredDate(value);
            case "endDate":
                return validateRequiredDate(value);
            case "endTime":
                return validateRequiredDate(value);
            case "status":
                return validateRequiredText(value);
            case "unitPrice":
                if (updatedTickets.hasPrice.value === true) {
                    return validateRequiredNumber(value, 1, 99999);
                }
                return validateNone();
            case "currency":
                if (updatedTickets.hasPrice.value === true) {
                    return validateRequiredText(value);
                }
                return validateNone();
            default:
                return validateNone();
        }
    };

    const handleSubmit = (e) => {
        if (e === "prev") {
            increaseStep("prev");
        } else {
            setFormState({ ...formState, ticketInfo: ticketsInfo });
            setSubmit(true);
        }
    };

    const handleAddTicket = () => {
        if (ticketsInfo.length < 5) {
            const newIndex = ticketsInfo.length;
            setTickets(prev => ({
                ...prev,
                [newIndex]: { ...INITIAL_TICKET_STATE }
            }));
            setIsNewTicket(true);
            toggleEditTicketModal(newIndex);
        }
    };

    const handleSaveTicket = (index) => {
        let hasError = false;
        const updatedTickets = { ...tickets[index] };

        for (const key in updatedTickets) {
            const value = updatedTickets[key].value;
            const error = validateField(key, value, index);
            if (error.msg !== null) {
                hasError = true;
                updatedTickets[key] = { value, error: error.msg };
            }
        }

        if (!hasError) {
            const flatObject = Object.keys(updatedTickets).reduce((acc, key) => ({
                ...acc,
                [key]: updatedTickets[key].value instanceof Date
                    ? updatedTickets[key].value.toISOString()
                    : updatedTickets[key].value
            }), {});

            // Keep track of whether this is a new or modified ticket
            if (!flatObject.ticketId) {
                flatObject._isNew = true;
            } else {
                flatObject._isModified = true;
            }

            setTicketsInfo(prev => {
                const updated = [...prev];
                updated[index] = flatObject;
                return updated;
            });

            setHasUnsavedChanges(true);
            toggleEditTicketModal(index);
            setIsNewTicket(false);
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setTickets(prev => ({
            ...prev,
            [openIndex]: {
                ...prev[openIndex],
                [name]: { value: checked, error: null }
            }
        }));
    };

    const toggleEditTicketModal = (index) => {
        setShowTicketForm(prev => !prev);
        setOpenIndex(prev => prev === index ? null : index);
    };

    const closeModal = (index) => {
        setIsNewTicket(false);
        toggleEditTicketModal(index);
    };

    const handleUnitPriceChange = (e) => {
        const { name, value } = e.target;
        setTickets(prev => ({
            ...prev,
            [openIndex]: {
                ...prev[openIndex],
                [name]: { value, error: null }
            }
        }));
    };

    const handleDeleteTicket = (e, index) => {
        e.stopPropagation();
        const ticketToDelete = ticketsInfo[index];

        // Check if ticket can be deleted
        if (!canDeleteTicket(ticketToDelete)) {
            alert("Cannot delete ticket with existing sales");
            return;
        }

        // Remove from ticketsInfo array immediately for UI update
        const updatedTicketsInfo = ticketsInfo.filter((_, idx) => idx !== index);

        if (ticketToDelete.ticketId) {
            // For existing tickets, add deletion marker before removing from view
            updatedTicketsInfo.forEach(ticket => {
                if (ticket.ticketId === ticketToDelete.ticketId) {
                    ticket._markedForDeletion = true;
                }
            });
        }

        // Update all states
        setTicketsInfo(updatedTicketsInfo);

        // Update tickets state
        const newTickets = { ...tickets };
        delete newTickets[index];
        setTickets(newTickets);

        // Update form state to reflect deletion
        setFormState(prevState => ({
            ...prevState,
            ticketInfo: updatedTicketsInfo
        }));

        setHasUnsavedChanges(true);
    };

    // Render ticket list item
    const TicketListItem = ({ ticket, index }) => {
        const [showMenu, setShowMenu] = useState(false);
        const isDeleteable = canDeleteTicket(ticket);
        const menuRef = useRef(null);

        useClickOutside(menuRef, () => setShowMenu(false));

        return (
            <div
                key={index}
                className={`flex flex-col mt-5 hover:ring-blue-400 hover:ring-2 w-full`}
            >
                <div className="bg-white shadow-md w-full p-4 sm:p-6 relative">
                    <div className="flex flex-col sm:grid sm:grid-cols-6 gap-4">
                        {/* Main Content */}
                        <div
                            className="sm:col-span-5 cursor-pointer"
                            onClick={() => toggleEditTicketModal(index)}
                        >
                            <h2 className="text-lg font-medium mb-2 text-slate-800 truncate">{ticket.title}</h2>
                            <span className="line-clamp-2">{ticket.description}</span>
                            <span className='mt-2 text-sm block'>
                                {convertDateToHuman(ticket.startDate)} - {convertDateToHuman(ticket.endDate)}
                            </span>
                        </div>

                        {/* Price and Menu Section */}
                        <div className='sm:col-span-1 flex items-center justify-between sm:justify-end space-x-4 mt-4 sm:mt-0'>
                            <div className="flex-shrink-0">
                                {parseFloat(ticket.unitPrice) > parseFloat(0.00) ? (
                                    <p className="text-slate-600 whitespace-nowrap">
                                        {getCurrencyFormat(ticket.unitPrice, ticket.currency)}
                                    </p>
                                ) : (
                                    <p className="text-slate-600">Free</p>
                                )}
                            </div>

                            {/* Menu Button */}
                            <div className="relative flex-shrink-0" ref={menuRef}>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowMenu(!showMenu);
                                    }}
                                    className="p-1 hover:bg-gray-100 border-none rounded-full"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                    </svg>
                                </button>

                                {/* Dropdown Menu */}
                                {showMenu && (
                                    <div className="absolute right-0 top-full mt-1 w-48 bg-white shadow-lg z-20 rounded-md overflow-hidden">
                                        {isDeleteable ? (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteTicket(e, index);
                                                    setShowMenu(false);
                                                }}
                                                className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-50 border-none"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4 mr-2"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                    />
                                                </svg>
                                                Delete
                                            </button>
                                        ) : (
                                            <div className="px-4 py-2 text-sm text-gray-400 cursor-not-allowed">
                                                Delete
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Empty State Component
    const EmptyState = () => (
        <div className="flex h-[40rem]">
            <div className="m-auto">
                <div className='flex flex-col items-center text-slate-500'>
                    <img src={emptyState} alt="" width={250} height={250} />
                    <span>You don't have any tickets, please create one in order to continue</span>
                    <div className='mt-4'>
                        <button
                            onClick={handleAddTicket}
                            className='bg-[#529535] text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-[#529535] hover:bg-[#529535] hover:text-white hover:opacity-80 transition duration-200 ease-in-out h-auto w-fit'
                        >
                            Add Ticket
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className='flex min-h-full h-full mt-16 mx-auto justify-center w-full'>
            <div className={`mt-10 sm:mt-0 px-4 ${!editMode ? '' : 'w-[832px] max-w-[832px]'}`}>
                {!editMode && (
                    <div className='pb-8 mt-28 mx-12'>
                        <div className='text-[#003863] font-bold text-3xl'>Create Tickets</div>
                        <span className='text-slate-500'>
                            Add the different categories of tickets you would like to provide for this event.
                        </span>
                    </div>
                )}

                {ticketsInfo.length > 0 ? (
                    <>
                        <div className={`flex flex-col sm:mt-0 p-4 ${!editMode ? '' : ''}`}>
                            <div>
                                {ticketsInfo.map((ticket, index) => (
                                    <TicketListItem key={index} ticket={ticket} index={index} />
                                ))}
                                <div className='flex flex-col items-center mt-6 mx-auto'>
                                    <button
                                        className="ring-gray-900/10 hover:ring-gray-900/20 hover:bg-gray-100 text-gray-500 ring-1 rounded-full h-16 w-16 ml-4 border-gray-900/10"
                                        onClick={handleAddTicket}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
                                            <path fillRule="evenodd" d="M11 9V5a1 1 0 0 0-2 0v4H5a1 1 0 0 0 0 2h4v4a1 1 0 0 0 2 0v-4h4a1 1 0 0 0 0-2h-4z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {showTicketForm && openIndex !== null && tickets[openIndex] && (
                            <Modal onClose={() => closeModal(openIndex)}>
                                <TicketForm
                                    ticket={tickets[openIndex]}
                                    index={openIndex}
                                    onUpdateField={onUpdateField}
                                    onUpdateDateTime={onUpdateDateTime}
                                    handleBlur={handleBlur}
                                    handleSaveTicket={handleSaveTicket}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleUnitPriceChange={handleUnitPriceChange}
                                    editMode={editMode}
                                    isNewTicket={isNewTicket}
                                />
                            </Modal>
                        )}

                        {!editMode && (
                            <StepperControl
                                handleClick={handleSubmit}
                                currentStep={currentStep}
                                steps={steps}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <EmptyState />
                        {showTicketForm && openIndex !== null && (
                            <Modal onClose={() => closeModal(openIndex)}>
                                <TicketForm
                                    ticket={tickets[openIndex]}
                                    index={openIndex}
                                    onUpdateField={onUpdateField}
                                    onUpdateDateTime={onUpdateDateTime}
                                    handleBlur={handleBlur}
                                    handleSaveTicket={handleSaveTicket}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleUnitPriceChange={handleUnitPriceChange}
                                    editMode={editMode}
                                    isNewTicket={isNewTicket}
                                />
                            </Modal>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default TicketInfo;