import React, { useEffect, useState } from 'react';
import StepperControl from '../components/StepperControl';
import EventInfo from '../components/steps/EventInfo';
import VenueInfo from '../components/steps/VenueInfo';
import TicketInfo from '../components/steps/TicketInfo';
import AdvancedSettings from '../components/steps/AdvancedSettings';
import { useAuth0 } from '@auth0/auth0-react';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { requestAlterationOfEvent, resetAlterationOfEvent } from '../store/actions/index';
import { classNames } from '../components/shared/Utils';


const EditEvent = () => {
    const submitEvent = useSelector((state) => state.organizerEditEvent.event);
    const submitEventError = useSelector((state) => state.organizerEditEvent.error);
    const submitEventLoading = useSelector((state) => state.organizerEditEvent.loading);
    const organizationEvents = useSelector((state) => state.organizerEvents.orgEvents);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);

    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orgId, offerId } = useParams();

    const [loading, setLoading] = useState(true);
    const [formState, setFormState] = useState(null);
    const [initialFormState, setInitialFormState] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [changes, setChanges] = useState(null);

    const populateFormState = (json) => {
        const {
            status,
            title,
            short_description,
            long_description,
            category_name,
            img_url,
            start_time,
            end_time,
            organizer,
            location,
            tickets,
            advancedSettings
        } = json;

        const populatedState = {
            orgInfo: { orgId: { value: organizer.id, error: null } },
            eventInfo: {
                status: { value: status, error: null },
                eventName: { value: title, error: null },
                shortDescription: { value: short_description, error: null },
                eventImgFile: { value: img_url, error: null },
                startDate: { value: new Date(start_time), error: null },
                startTime: { value: new Date(start_time), error: null },
                endDate: { value: new Date(end_time), error: null },
                endTime: { value: new Date(end_time), error: null },
                timezone: { value: Intl.DateTimeFormat().resolvedOptions().timeZone, error: null },
                longDescription: { value: long_description, error: null },
                category: { value: category_name, error: null }
            },
            venueInfo: {
                venueId: { value: null, error: null },
                venue: { value: location.name, error: null },
                addressLine1: { value: location.address_line_1, error: null },
                addressLine2: { value: location.address_line_2 || '', error: null },
                city: { value: location.city, error: null },
                state: { value: location.state, error: null },
                postalCode: { value: location.country, error: null },
                country: { value: location.country, error: null }
            },
            ticketInfo: tickets.map((ticket) => ({
                ticketId: ticket.ticket_id,
                title: ticket.title,
                description: '',
                quantity: ticket.quantity,
                min: ticket.min_per_person,
                max: ticket.max_per_person,
                unitPrice: ticket.price,
                currency: 'USD',
                startDate: new Date(ticket.startDate),
                endDate: new Date(ticket.endDate),
                startTime: new Date(ticket.startDate),
                endTime: new Date(ticket.endDate),
                hasPrice: ticket.price > 0,
                status: ticket.status,
                quantity_sold: ticket.quantity_sold
            })),
            advancedSettings: {
                timezone: { value: advancedSettings.timezone, error: null },
                currency: { value: advancedSettings.currency, error: null },
                hideEvent: { value: advancedSettings.hideEvent, error: null },
                requireAccessCode: { value: advancedSettings.requireAccessCode, error: null },
                accessCode: { value: advancedSettings.accessCode, error: null },
                hideShareButtons: { value: advancedSettings.hideShareButtons, error: null }
            }
        };

        const emptyTicketState = {
            ...populatedState,
            ticketInfo: []
        };

        setInitialFormState(populatedState);
        setFormState(populatedState);
    };


    function isEqual(obj1, obj2) {
        if (obj1 === obj2) return true;
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            // Check for nested equality
            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!isEqual(val1, val2)) return false;
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    }

    function findTicketChanges(initialArray, updatedArray) {
        const initialMap = new Map(initialArray.map(obj => [obj.ticketId, obj]));
        const changes = [];

        updatedArray.forEach(updatedObj => {
            const initialObj = initialMap.get(updatedObj.ticketId);
            if (!initialObj || !isEqual(initialObj, updatedObj)) {
                changes.push(updatedObj);
            }
        });

        return changes;
    }

    function findChanges(initialState, modifiedState) {
        const differences = {};

        // Recursive function to compare nested objects and arrays
        const compareObjects = (obj1, obj2, path = []) => {
            for (const key in obj1) {
                const currentPath = [...path, key];
                const value1 = obj1[key];
                const value2 = obj2[key];

                // if (Array.isArray(value1) && Array.isArray(value2)) {
                //     // Compare arrays..this is specific for Ticket Info
                //     console.log("value 1", value1);
                //     console.log("value 2", value2);
                //     if (value1.length !== value2.length) {
                //         // return the deltas to the changes object
                //         differences[currentPath.join('.')] = {
                //             oldValue: value1,
                //             newValue: value2,
                //         };

                //     } else {
                //         // Recursively compare array elements
                //         // if there are any changes the we send the entire array
                //         value1.forEach((item, index) => {
                //             compareObjects(item, value2[index], [...currentPath, index]);
                //         });
                //     }
                if (Array.isArray(value1) && Array.isArray(value2)) {
                    // Compare arrays
                    if (value1.length !== value2.length) {
                        const maxLength = Math.max(value1.length, value2.length);
                        const delta = [];
                        for (let i = 0; i < maxLength; i++) {
                            const item1 = value1[i] !== undefined ? value1[i] : null;
                            const item2 = value2[i] !== undefined ? value2[i] : null;
                            if (item1 !== item2) {
                                delta.push(item2);
                            }
                        }
                        differences[currentPath.join('.')] = {
                            oldValue: value1,
                            newValue: delta,
                        };
                    } else {
                        // Recursively compare array elements
                        // if there are any changes the we send the entire array
                        // value1.forEach((item, index) => {
                        //     compareObjects(item, value2[index], [...currentPath, index]);
                        // });
                        const delta = findTicketChanges(value1, value2)
                        differences[currentPath.join('.')] = {
                            oldValue: value1,
                            newValue: delta,
                        };
                    }
                } else if (value1 instanceof Date && value2 instanceof Date) {
                    // Compare dates
                    if (value1.getTime() !== value2.getTime()) {
                        differences[currentPath.join('.')] = {
                            oldValue: value1,
                            newValue: value2,
                        };
                    }
                } else if (typeof value1 === 'object' && typeof value2 === 'object') {
                    // Recursively compare nested objects
                    compareObjects(value1, value2, currentPath);
                } else if (value1 !== value2) {
                    // Compare other values
                    differences[currentPath.join('.')] = {
                        oldValue: value1,
                        newValue: value2,
                    };
                }
            }
        };

        compareObjects(initialState, modifiedState);
        return differences;
    }


    const submitForm = async () => {
        const accessToken = await getAccessTokenSilently();
        const trimmedState = trimForm(formState, changes);
        dispatch(requestAlterationOfEvent(trimmedState, orgId, offerId, accessToken));
    };

    const publishEvent = async (action) => {
        console.log(initialFormState.eventInfo.status.value);
        const accessToken = await getAccessTokenSilently();
        const trimmedState = {
            eventInfo: {
                status: action === "publish" ? "LIVE" : "DRAFT"
            }
        }
        dispatch(requestAlterationOfEvent(trimmedState, orgId, offerId, accessToken));
    };

    const trimForm = (originalObject, changes) => {
        const convertedObject = {};

        for (const key in changes) {
            const change = changes[key];
            const keys = key.split('.');

            let currentObj = convertedObject;
            for (let i = 0; i < keys.length - 2; i++) {
                const currentKey = keys[i];
                currentObj[currentKey] = currentObj[currentKey] || {};
                currentObj = currentObj[currentKey];
            }

            const lastKey = keys[keys.length - 2] ?? keys[keys.length - 1];
            const newValue = change.newValue;

            if (Array.isArray(newValue)) {
                currentObj[lastKey] = newValue.map((ticket) => {
                    const trimmedTicket = {};
                    for (const ticketKey in ticket) {
                        if (ticketKey !== 'value') {
                            trimmedTicket[ticketKey] = ticket[ticketKey];
                        }
                    }
                    return trimmedTicket;
                });
            } else {
                currentObj[lastKey] = newValue;
            }
        }

        return convertedObject;
    };

    useEffect(() => {
        const selectedEvent = organizationEvents.find((event) => event.id == offerId);
        if (selectedEvent) {
            populateFormState(selectedEvent);
            setLoading(false);
        }
    }, [orgId, offerId]);

    useEffect(() => {
        if (!submitEventLoading && Object.keys(submitEventError).length > 1) {
            console.log('There is an error, cannot move on');
        } else if (!submitEventLoading && Object.keys(submitEvent).length > 1) {
            if ('eventId' in submitEvent) {
                dispatch(resetAlterationOfEvent());
                navigate(-1);
            }
        }
    }, [submitEvent, submitEventLoading, submitEventError, navigate]);

    useEffect(() => {
        if (initialFormState && formState) {
            const differences = findChanges(initialFormState, formState);
            console.log('Changes:', differences);
            setChanges(differences);
            setHasChanges(Object.keys(differences).length !== 0);
        }
    }, [formState, initialFormState]);

    const StatusPill = (value) => {
        let status = value ? value.toLowerCase() : "unknown";
        if (status.startsWith("live")) {
            status = "On Sale";
        }
        if (status.startsWith("started")) {
            status = "live";
        }
        return (
            <span className={classNames("px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("live") ? "pulsate-live" : null,
                status.startsWith("canceled") ? "bg-[#D439001A] text-[#D43900]" : null,
                status.startsWith("draft") ? "bg-[#008BBF1A] text-[#003863]" : null,
                status.startsWith("ended") ? "bg-[#F7911D26] text-[#474B55]" : null,
                status.startsWith("On Sale") ? "bg-[#5295351A] text-[#3E7128]" : null //"bg-[#7B61FF1A] text-[#5C2581]" : null
            )}>
                {status}
            </span>
        );
    };

    return (

        !loading ?

            <div className='p-4 lg:ml-64 min-h-screen lg:pb-10' >
                <div className='flex flex-col items-start pb-6 space-y-4 border-b md:items-center md:space-y-0 md:flex-row m-4 mt-8 justify-between'>
                    <div className='flex flex-row items-center w-full overflow-hidden'>
                        <h1 className='text-2xl font-semibold truncate max-w-[70%]'>
                            {initialFormState.eventInfo.eventName.value}
                        </h1>
                        <span className='ml-4 flex-shrink-0'>
                            {StatusPill(initialFormState.eventInfo.status.value)}
                        </span>
                    </div>
                    <div className='flex flex-row'>
                        {
                            initialFormState.eventInfo.status.value.toLowerCase() === 'live' ?

                                (
                                    <div
                                        className="bg-white border border-gray-300 py-2 px-4 hover:bg-gray-100 font-semibold rounded-md hover:cursor-pointer text-[#761c17]"
                                        onClick={() => publishEvent('unpublish')}
                                    >
                                        Unpublish
                                    </div>
                                )
                                :
                                (
                                    <div
                                        className="bg-[#0453a3] hover:opacity-90 rounded-md px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm w-fit hover:cursor-pointer"
                                        onClick={() => publishEvent('publish')}
                                    >
                                        Publish
                                    </div>
                                )

                        }
                    </div>
                </div>
                <div className='md:max-w-[1166px] lg:max-w-[1166px] mx-auto my-2 flex flex-row justify-between'>
                    <div className='flex flex-row text-[#474B55] font-medium cursor-pointer hover:font-semibold' onClick={() => navigate(-1)}>
                        <BsArrowLeftShort size={25} /> BACK
                    </div>



                </div>
                <div className='flex flex-col w-full md:max-w-[1166px] lg:max-w-[1166px] mx-auto pb-2 bg-white border rounded-lg'>
                    {!submitEventLoading && !loading ? (
                        <>
                            <EventInfo formState={formState} setFormState={setFormState} editMode={true} />
                            <hr className="mt-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400" />
                            <VenueInfo formState={formState} setFormState={setFormState} editMode={true} />
                            <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400" />
                            <TicketInfo formState={formState} setFormState={setFormState} editMode={true} />
                            <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400" />
                            <AdvancedSettings formState={formState} setFormState={setFormState} editMode={true} />
                            <div className='mt-24'>
                                <StepperControl
                                    handleClick={submitForm}
                                    currentStep={1}
                                    steps={1}
                                    nextText={'Save'}
                                    isNextDisabled={!hasChanges}
                                />
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>

                <div className='flex flex-col md:max-w-[1166px] lg:max-w-[1166px] mx-auto mt-20 mb-4'>
                    <h1 className='text-xl font-semibold whitespace-nowrap'>Danger Zone</h1>
                </div>


                <div className='flex flex-row w-full md:max-w-[1166px] lg:max-w-[1166px] mx-auto bg-[#fbeeed] border-none rounded-md text-[#761c17] justify-between px-4 py-6'>
                    <div className='flex flex-col'>
                        <h2 className='font-semibold mb-3'>Delete/Cancel Event</h2>
                        <p className='text-sm'>All Patrons whom have purchased tickets will be automatically refunded and notification emails will be sent about event cancellation</p>
                    </div>


                    <div
                        className="bg-[#c32f26] rounded-md px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:opacity-85 hover:cursor-pointer w-fit flex self-center">
                        Delete
                    </div>
                </div>
            </div >

            :
            <></>
    );
};

export default EditEvent;
