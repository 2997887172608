import React from 'react'
// import { Card, Metric, Text, List, ListItem, ProgressBar, Grid } from "@tremor/react";
import {
    ProgressBar,
    Card,
    Flex,
    Text,
    Metric,
    TabList,
    Tab,
    TabGroup,
    TabPanels,
    TabPanel,
    Title,
    List,
    ListItem,
} from "@tremor/react";
import { useState } from "react";

// import { UserGroupIcon, UserIcon } from "@heroicons/react/solid";
// import { BeakerIcon } from '@heroicons/react/24/solid'



const TicketSalesProgressBar = ({ dataSet, eventName }) => {


    const ticketBreakdown = dataSet.map(ticket => {
        const share = ((ticket.quantity_sold / ticket.total_ticket_available) * 100).toFixed(0);
        const amount = (parseFloat(ticket.price) * ticket.quantity_sold).toFixed(2);
        return {
            name: ticket.title,
            share: parseInt(share),
            amount: `$ ${amount}`,
        };
    });

    return (
        <div className='h-full'>
            <Card className='h-full'>
                <Title>Ticket Sales By Type</Title>
                <Text>Tickets sales for each ticket type available for this event.</Text>
                {/* <Metric>$ 442,276</Metric> */}
                <List className="mt-4">


                    {
                        ticketBreakdown.length > 0 ?

                            (
                                ticketBreakdown.map((product) => (
                                    <ListItem key={product.name}>
                                        <div className="w-full">
                                            <Text>{product.name}</Text>
                                            <ProgressBar
                                                value={product.share}
                                                label={`${product.share}%`}
                                                tooltip={product.amount}
                                                color='lime'
                                            // color='#003863'
                                            // color='#F7911D'
                                            // color='#82256F'
                                            />
                                        </div>
                                    </ListItem>
                                ))
                            )

                            :

                            <div class="tremor-Flex-root flex flex-row justify-center items-center w-full h-full border border-dashed rounded-tremor-default border-tremor-border min-h-[20rem]">
                                <p class="text-tremor-default text-tremor-content ">
                                    No data
                                </p>
                            </div>
                    }
                </List>
            </Card>
        </div>
    )
}

export default TicketSalesProgressBar