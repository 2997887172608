import React, { useEffect, useState } from 'react'
import { } from "react-icons/";
import { BsEyeFill, BsTwitter, BsFacebook, BsInstagram, BsGlobe, BsCalendar, BsWhatsapp, BsArrowLeftShort, BsShare, BsInfoCircle } from 'react-icons/bs';
import { FaFacebookF, FaFacebookMessenger, FaLinkedinIn, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown'
import { useNavigate, useLocation, useParams, useMatches } from "react-router-dom";
import CheckoutModal from '../components/offer/CheckoutModal';

import Loader from '../components/Loader';
import { convertDateToHuman } from '../components/common/dateUtils';
import { getCurrencyFormat } from '../components/common/currencyUtils';
import { requestOfferDetails, resetVerifyAccesscode, resetOfferDetails } from '../store/actions/index';
import AccessVerification from '../components/verification/AccessVerification';


import wave_img from '../images/wave.svg';
import wave_pattern from '../images/wave-pattern.svg';
import xLogo from '../images/x_logo.svg';
import defaultOrg from '../images/default_org.png'


const Offer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { offerId } = useParams();
    const isPreview = window.location.pathname.includes('/preview');
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);

    // const offer = useSelector((state) => state.offerDetail.detailList);
    // const offerLoading = useSelector((state) => state.offerDetail.loading);

    // Get initial event data and verification status
    const initialEventData = useSelector((state) => state.offerDetail.detailList);
    const offerLoading = useSelector((state) => state.offerDetail.loading);
    const verifyAccessCodeStatus = useSelector(state => state.verifyAccesscode.verifyAccesscode);

    // Reset states when component mounts and unmounts
    useEffect(() => {
        // Reset states when component mounts with new offerId
        dispatch(resetVerifyAccesscode());
        dispatch(resetOfferDetails());

        // Fetch initial data
        if (offerId) {
            dispatch(requestOfferDetails(offerId));
        }

        // Cleanup when component unmounts
        return () => {
            dispatch(resetVerifyAccesscode());
            dispatch(resetOfferDetails());
        };
    }, [offerId, dispatch]);

    if (offerLoading) {
        return <Loader />;
    }

    // If we don't have initial event data yet
    if (!initialEventData || Object.keys(initialEventData).length === 0) {
        return <Loader />;
    }

    const handleShareClick = async (e) => {
        // e.preventDefault();
        try {
            // console.log("boop boop boop");
            const domain = window.location.origin;
            await navigator.share({
                text: 'Check out this event on Lymetickets.com',
                url: `${domain}/events/${offerId}`,
            });
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };

    function handleShowModal(state) {
        setShowCheckoutModal(state)
        if (state === true) {
            document.body.classList.add('body-no-scroll');
        } else {
            document.body.classList.remove('body-no-scroll');
        }

    }

    // Determine which event data to use based on access code requirement
    const eventData = initialEventData?.requiresAccessCode ?
        verifyAccessCodeStatus?.data?.eventDetails[0] || initialEventData :
        initialEventData;

    const OfferContent = ({ offer, isPreview, showCheckoutModal, setShowCheckoutModal, handleNavigate }) => {
        // Your existing offer content JSX here, but using event prop instead of Redux selector
        return (
            <>

                {
                    isPreview &&

                    <div className='text-white text-sm text-center leading-4 w-full fixed z-30 border-t-2 border-[#D43900]'>
                        <div className='bg-[#D43900] w-28 pb-1 px-2 text-center mx-auto rounded-b-lg font-medium'>
                            Preview mode
                        </div>
                    </div>
                }

                <div className='newspaper'>
                    {/* background blur */}
                    {/* <div className='w-full h-[720px] bg-cover bg-center -mb-[600px] ' style={{ backgroundImage: `url(${offer.img_url})` }} > */}

                    {/* <div className='absolute w-full h-full bg-cover bg-center overflow-hidden' >
                                <div className='w-full h-full flex justify-center items-center backdrop-blur-3xl bg-gradient-to-b to-white from-transparent' >
                                    <img className="w-[100vw] blur-[150px] animate-pulse animate-infinite animate-alternate animate-ease-in-out animation-duration-10" src={offer.img_url} alt="" />
                                </div>
                            </div > */}

                    {/* <div className="bg-gray-900 relative overflow-hidden py-16 lg:py-32 lg:px-48 px-6">
                                <img className="w-[80vw] filter blur-[150px] hidden md:block absolute -right-[30vw] top-0 animate-spin animate-infinite animate-alternate animate-ease-in-out animate-duration-[10000ms]" src={wave_img} alt="" />
                                <span className="absolute left-0 right-0 top-0 bottom-0 " style={{ backgroundImage: `url(${wave_pattern})` }}></span>
                            </div> */}

                    {/* <div class="css-v2ji9k"><div class="css-1sqhngh"></div><div class="css-1sqhngh css-1s4f605"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div><div class="css-1sqhngh"></div></div> */}

                    {
                        !isPreview &&
                        <div className='flex bg-white mt-6 ml-3 lg:ml-0'>
                            <div id='event-back-navigation' className="flex flex-row text-[#474B55] font-medium cursor-pointer hover:font-semibold " onClick={() => navigate(-1)}>
                                <BsArrowLeftShort size={25} />
                                BACK
                            </div>
                            {/* <div
                                        onClick={() => navigate(-1)}
                                        target='_blank'
                                        className="inline-flex h-10 align-middle border-none self-center items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <BsArrowLeftShort size={25} />
                                        BACK
                                    </div> */}
                        </div>
                    }


                    <div className='flex justify-center mt-6 mb-20'>
                        <div className='flex flex-col w-full md:max-w-[944px] lg:max-w-[1166px] lg:grid lg:grid-cols-3 lg:gap-0 bg-white'>

                            {/* left side */}
                            <div className='lg:col-span-2 z-20 shadow-md rounded-md bg-white'>
                                {/* offer background */}
                                <div className='w-full rounded-md'>
                                    <img src={offer.img_url} alt='offer display' />
                                </div>

                                {/* offer short description */}
                                <div className='block p-6 text-lg font-semibold border-b-[1px]'>
                                    <div className='flex justify-between'>
                                        {offer.title}

                                        <div className='flex'>
                                            <BsShare size={17} className="text-[#0062AB] z-10 hover:cursor-pointer" onClick={handleShareClick} />
                                        </div>
                                    </div>


                                    {/* offer by */}
                                    <div className='pt-5 font-light text-sm flex'>
                                        By {offer.organizer.name}
                                        {
                                            (
                                                (offer.organizer.is_verified) ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 fill-blue-500">
                                                        <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                    </svg>
                                                    : ''
                                            )
                                        }
                                    </div>

                                    {/* views count */}
                                    {/* <div className='flex items-center mt-2'>
                                                <BsEyeFill size={16} className="text-[#757575] opacity-30" />
                                                <span className='pl-1 text-[13px] text-[#757575] opacity-70 px-2 w-10 items-start'>55k</span>
                                            </div> */}
                                </div>

                                {/* when and where */}
                                <div className='flex flex-col p-6 lg:hidden'>
                                    <h3 className='font-semibold text-2xl pb-2'>When and where</h3>

                                    <div className='flex'>
                                        <BsCalendar size={40} className="bg-white text-[#008bbf] font-bold p-2 rounded-md min-w-[40px] border" />
                                        <div className='flex flex-col ml-2'>
                                            <h4>Date and time</h4>
                                            <span className='text-[#757575]'>{convertDateToHuman(offer.start_time)} - {convertDateToHuman(offer.end_time)}</span>
                                        </div>
                                    </div>

                                    <div className='flex mt-5'>
                                        <MdLocationPin size={40} className="bg-white text-[#008bbf] font-bold p-2 rounded-md min-w-[40px] border" />
                                        <div className='flex flex-col ml-2'>
                                            <h4>Date and time</h4>
                                            <span className='text-[#757575]'>{offer.location.name}, {offer.location.city}, {offer.location.country}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Offer long description */}
                                <div className='p-6'>
                                    <div className='font-semibold text-2xl pb-2'>About this offer</div>
                                    <p className='text-justify text-[#757575] break-words'>
                                        <div dangerouslySetInnerHTML={{ __html: offer.long_description }} />
                                    </p>


                                    {/* Tags */}
                                    <div className='flex flex-col mt-5'>
                                        <h3 className=''>Tags</h3>

                                        <ul>
                                            {offer.tags.map((item, key) => (
                                                <li className='inline' key={key}>
                                                    <div className='text-xs inline-flex items-center font-bold leading-5 px-3 py-1 bg-gray-200 text-gray-800 rounded-full h-9 mt-4 mx-1'>{item.name}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>


                                    {/* <div className='flex flex-col mt-5'>
                                            <h3 className=''>Share with friends</h3>
        
                                            <ul className='flex pt-5'>
                                                <li>
                                                    <FaFacebookF size={20} className="mx-2 text-black" />
                                                </li>
                                                <li>
                                                    <FaFacebookMessenger size={20} className="mx-2 text-black" />
                                                </li>
                                                <li>
                                                    <FaTwitter size={20} className="mx-2 text-black" />
                                                    <img src={xLogo} alt='' className='bg-black w-5 text-black' />
                                                </li>
                                                <li>
                                                    <FaLinkedinIn size={20} className="mx-2 text-black" />
                                                </li>
                                                <li>
                                                    <FaEnvelope size={20} className="mx-2 text-black" />
                                                </li>
                                            </ul>
                                        </div> */}
                                </div>

                                {/* About the org*/}
                                <div className='p-6'>
                                    <div className='font-semibold text-2xl pb-2'>About Us</div>

                                    <div className='flex p-6 justify-center'>
                                        <img className={`p-1 h-24 w-24 max-w-fit border-[#e6e8ed] border-[1px] bg-white text-black rounded-full object-cover ${offer.organizer.img_url != null ? '' : 'grayscale'}`} src={offer.organizer.img_url != null ? offer.organizer.img_url : defaultOrg} alt="organization logo" />
                                    </div>

                                    <span className='flex px-6 mb-6 justify-center font-normal text-md'>{offer.organizer.name}
                                        {
                                            (
                                                (offer.organizer.is_verified) ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 fill-blue-500">
                                                        <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                    </svg>
                                                    : ''
                                            )
                                        }
                                    </span>

                                    <p className='text-[#757575] text-center'>
                                        {offer.organizer.bio}
                                    </p>
                                </div>

                                {/* Contact & Social Media Links */}
                                <div className='flex flex-col justify-center pb-10'>
                                    <div className='flex self-center'>
                                        <ul className='inline-flex'>

                                            {
                                                (
                                                    (offer.organizer.social_media.facebook != null)
                                                        ? <a href={offer.organizer.social_media.facebook}><li><BsFacebook size={40} className="mx-2 bg-[#eeedf2] text-black rounded-full p-2" /></li></a>
                                                        : ''
                                                )
                                            }{
                                                (
                                                    (offer.organizer.social_media.twitter != null)
                                                        ? <a href={offer.organizer.social_media.twitter}><li><BsTwitter size={40} className="mx-2 bg-[#eeedf2] text-black rounded-full p-2" /></li></a>
                                                        : ''

                                                )
                                            }
                                            {
                                                (
                                                    (offer.organizer.social_media.instagram != null)
                                                        ? <a href={offer.organizer.social_media.instagram}><li><BsInstagram size={40} className="mx-2 bg-[#eeedf2] text-black rounded-full p-2" /></li></a>
                                                        : ''
                                                )
                                            }{
                                                (
                                                    (offer.organizer.social_media.website != null)
                                                        ? <a href={offer.organizer.social_media.website}><li><BsGlobe size={40} className="mx-2 bg-[#eeedf2] text-black rounded-full p-2" /></li></a>
                                                        : ''

                                                )
                                            }{
                                                (
                                                    (offer.organizer.social_media.whatsapp != null)
                                                        ? <a href={offer.organizer.social_media.whatsapp}><li><BsWhatsapp size={40} className="mx-2 bg-[#eeedf2] text-black rounded-full p-2" /></li></a>
                                                        : ''

                                                )
                                            }

                                        </ul>
                                    </div>

                                    {/* <div className='flex p-6 justify-evenly mt-5'>
                                                <div className="border-solid border-2 border-indigo-600 p-2 rounded-md w-[100px] text-center">Follow</div>
                                                <div className="border-solid border-2 border-indigo-600 p-2 rounded-md w-[100px] text-center">Contact</div>
                                            </div> */}
                                </div>
                            </div>

                            {/* Right side */}
                            <div className='block z-20 fixed bottom-0 border-gray-100 shadow-md bg-white w-full lg:col-span-1 lg:sticky lg:h-fit lg:top-[105px]'>

                                <div className='hidden lg:block p-6 text-lg font-semibold border-b-[1px]'>
                                    {offer.title}

                                    {/* offer by */}
                                    <div className='pt-5 font-light text-sm'>
                                        By {offer.organizer.name}
                                    </div>

                                    {/* views count */}
                                    {/* <div className='flex items-center mt-2'>
                                                <BsEyeFill size={16} className="text-[#757575] opacity-30" />
                                                <span className='pl-1 text-[13px] text-[#757575] opacity-70 px-2 w-10 items-start'>55k</span>
                                            </div> */}
                                </div>

                                {/* when and where */}
                                <div className='hidden xl:flex flex-col p-6'>
                                    <h3 className='font-semibold text-2xl pb-2'>When and where</h3>

                                    <div className='flex'>
                                        <BsCalendar size={40} className="bg-white text-[#008bbf] font-bold p-2 rounded-md min-w-[40px] border" />
                                        <div className='flex flex-col ml-2'>
                                            <h4>Date and time</h4>
                                            <span className='text-[#757575] block'>{convertDateToHuman(offer.start_time)} - {convertDateToHuman(offer.end_time)}</span>
                                        </div>
                                    </div>

                                    <div className='flex mt-5'>
                                        <MdLocationPin size={40} className="bg-white text-[#008bbf] font-bold p-2 rounded-md min-w-[40px] border" />
                                        <div className='flex flex-col ml-2'>
                                            <h4>Location</h4>
                                            <span className='text-[#757575]'>{offer.location.name}, {offer.location.city}, {offer.location.country}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='h-[100px] max-h-[140px] w-full p-6'>
                                    {/* <div className='flex justify-center mb-5'>{getCurrencyFormat(Offer.attributes.price.minPrice)} - {getCurrencyFormat(Offer.attributes.price.maxPrice)}</div> */}

                                    {isPreview ? (
                                        <button
                                            className='flex bg-[#82C342] justify-center w-full py-4 rounded-sm shadow-sm text-white border-none text-lg opacity-50 cursor-not-allowed'
                                        >
                                            Tickets
                                        </button>
                                    ) : (
                                        <button
                                            className='flex bg-[#009B00] justify-center w-full py-4 rounded-sm shadow-sm font-semibold text-white hover:opacity-90 hover:cursor-pointer border-none text-lg'
                                            onClick={() => handleShowModal(true)}
                                        >
                                            Tickets
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {showCheckoutModal && <CheckoutModal organizationMode={false} eventId={offerId} showCheckoutModal={showCheckoutModal} onClose={() => handleShowModal(false)} />}
                </div>

            </>
        );
    };

    return (
        <AccessVerification event={initialEventData}>
            <OfferContent
                offer={eventData}
                isPreview={isPreview}
                showCheckoutModal={showCheckoutModal}
                setShowCheckoutModal={setShowCheckoutModal}
                handleNavigate={navigate}
            />
        </AccessVerification>
    );
}


export default Offer