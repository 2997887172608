import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../shared/Alert';
import { Button } from '../shared/Button';
import { Input } from '../shared/Input';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../shared/Card';
import { useAuth0 } from '@auth0/auth0-react';
import { requestVerifyAccesscode, resetVerifyAccesscode } from '../../store/actions';
import Loader from '../../components/Loader';


const AccessVerification = ({ children, event }) => {
    const [accessCode, setAccessCode] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [localError, setLocalError] = useState('');
    const { offerId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Get values from Redux store
    const verifyAccessCodeStatus = useSelector(state => state.verifyAccesscode.verifyAccesscode);
    const verifyAccessCodeLoading = useSelector(state => state.verifyAccesscode.loading);
    const error = useSelector(state => state.verifyAccesscode.error);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        return () => {
            dispatch(resetVerifyAccesscode());
        };
    }, [offerId, dispatch]);

    // Check verification status
    useEffect(() => {
        if (verifyAccessCodeStatus?.data?.verified) {
            setIsVerified(true);
        } else if (error) {
            setLocalError(error);
            setIsVerified(false);
        }
    }, [verifyAccessCodeStatus, error]);

    // If no access code required or already verified, show content
    if (!event?.advancedSettings?.requireAccessCode || isVerified) {
        return children;
    }

    // If access code is required but not verified yet, show minimal event details
    // Reset verification state when access code changes
    const handleVerifyAccessCode = async () => {
        try {
            setLocalError('');
            // Reset previous verification state before new attempt
            dispatch(resetVerifyAccesscode());

            if (!accessCode) {
                setLocalError('Access code is required');
                return;
            }

            const accessToken = await getAccessTokenSilently();
            dispatch(requestVerifyAccesscode(offerId, { accessCode }, accessToken));
        } catch (err) {
            setLocalError('Failed to verify access code. Please try again.');
        }
    };


    // Show loader while verifying
    if (verifyAccessCodeLoading) {
        return <Loader />;
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <Card className="w-full max-w-md mx-4">
                <CardHeader>
                    <CardTitle>Event Access Required</CardTitle>
                    <CardDescription>
                        Please enter the access code to view this event
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        <Input
                            type="text"
                            placeholder="Enter access code"
                            value={accessCode}
                            onChange={(e) => setAccessCode(e.target.value)}
                            className="w-full"
                        />

                        {localError && (

                            <Alert
                                variant="error"
                                message={localError}
                                onClose={() => setLocalError('')}
                                autoHideDuration={5000}
                            />
                        )}

                        <div className="flex gap-4">
                            <Button
                                onClick={handleVerifyAccessCode}
                                className="w-full"
                                disabled={verifyAccessCodeLoading}
                            >
                                {verifyAccessCodeLoading ? 'Verifying...' : 'Verify Access'}
                            </Button>
                            <Button
                                variant="outline"
                                onClick={() => navigate('/')}
                                className="w-full"
                            >
                                Go Back
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default AccessVerification;