import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetPurchaseTickets, resetCheckoutEventDetails } from '../store/actions/index';

const useResetCheckoutModal = (showModal) => {
    const dispatch = useDispatch();
    const [isResetComplete, setIsResetComplete] = useState(false);

    useEffect(() => {
        const reset = async () => {
            if (showModal) {
                dispatch(resetCheckoutEventDetails());
                dispatch(resetPurchaseTickets());
                // Wait for async actions to complete
                setIsResetComplete(true);
            } else {
                setIsResetComplete(false);
            }
        };
        reset();
    }, [showModal, dispatch]);

    return isResetComplete;
};

export default useResetCheckoutModal;