// import React, { useState, useEffect } from 'react'
// // import Table, { AvatarCell, SelectColumnFilter, StatusPill } from '../components/Table'
// import Table, { StatusPill, OrderNumberCell, DateCell } from '../components/Table'
// import { useDispatch, useSelector } from 'react-redux';
// import { requestMyOrders } from '../store/actions/index';
// import { useAuth0 } from '@auth0/auth0-react';
// import Loader from '../components/Loader';
// import useAccessToken from '../hooks/useAccessToken';


// const Order = () => {

//     const dispatch = useDispatch();

//     const myOrders = useSelector((state) => state.myOrders.ordersList);
//     const myOrdersLoading = useSelector((state) => state.myOrders.loading);
//     const { user, getAccessTokenSilently, getIdTokenClaims, isLoading, isAuthenticated } = useAuth0();

//     // useAccessToken()

//     useEffect(() => {
//         const loadOffers = async () => {
//             const accessToken = await getAccessTokenSilently();

//             dispatch(requestMyOrders(accessToken));
//         }

//         loadOffers();
//     }, []);

//     const columns = React.useMemo(() => [
//         {
//             Header: "Order Number",
//             accessor: 'order_id',
//             Cell: OrderNumberCell,
//         },
//         {
//             Header: "Name",
//             accessor: 'title',
//         },
//         {
//             Header: "Date",
//             accessor: 'order_date',
//             Cell: DateCell
//         },
//         {
//             Header: "Status",
//             accessor: 'status',
//             Cell: StatusPill,
//         }
//     ], [])

//     return (
//         <div>
//             {
//                 !myOrdersLoading ? (

//                     <div className="flex min-h-screen h-screen bg-white text-gray-900 mt-10 overflow-hidden">
//                         <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 overflow-auto">

//                             {/* <div className='lg:col-span-2'>
//                                 <h4 className='text-[#003863] text-xl font-semibold'>Pending</h4>
//                             </div>

//                             <div className="py-6">
//                                 <Table columns={columns} data={data.slice(0, 3)} />
//                             </div> */}

//                             <div className='lg:col-span-2 mt-4'>
//                                 <h4 className='text-gray-900 text-xl font-semibold'>Orders <span className='text-gray-500 text-xl'>{myOrders.length}</span></h4>
//                             </div>

//                             <div className="py-6">
//                                 <Table columns={columns} data={myOrders} />
//                             </div>
//                         </div>
//                     </div>)
//                     : < Loader />}
//         </div>
//     )
// }

// export default Order

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader';
import Table3 from '../components/Table3';
import { requestMyOrders } from '../store/actions/index';
import { classNames } from '../components/shared/Utils'


const Order = () => {
    const dispatch = useDispatch();

    const myOrders = useSelector((state) => state.myOrders.ordersList || []);
    const myOrdersLoading = useSelector((state) => state.myOrders.loading);

    const { getAccessTokenSilently } = useAuth0();

    const [sortingState, setSortingState] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const ordersArray = Array.isArray(myOrders) ? myOrders : [];
    const paginatedOrders = ordersArray.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    useEffect(() => {
        const loadOrders = async () => {
            const accessToken = await getAccessTokenSilently();
            dispatch(requestMyOrders(accessToken));
        };

        loadOrders();
    }, []);

    const columns = [
        {
            accessorKey: 'order_id',
            header: 'Order Number',
            cell: (info) => (
                <a
                    href={`/orders/${info.getValue()}`}
                    className="text-blue-600 hover:underline"
                >
                    #{String(info.getValue()).padStart(2, '0')}
                </a>
            ),
        },
        {
            accessorKey: 'title',
            header: 'Name',
            cell: (info) => info.getValue(),
        },
        {
            accessorKey: 'order_date',
            header: 'Date',
            cell: (info) =>
                new Date(info.getValue()).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                }),
        },
        {
            accessorKey: 'status',
            header: 'Status',
            cell: (info) => {
                const value = info.getValue(); // Get the value of the status
                const statusClass = classNames(
                    "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                    value.toLowerCase() === 'completed' ? "bg-[#d9fbd0] text-[#3E7128] border border-[#bee8b4]" : null,
                    value.toLowerCase() === 'refunded' ? "bg-[#e3e6ed] text-[#141824] border border-[#cbd0dd] bg-opacity-50" : null,
                    value.toLowerCase() === 'pending' ? "bg-[#c7ebff] text-[#005585] border border-[#96d9ff]" : null
                );

                return (
                    <span className={statusClass}>
                        {value}
                    </span>
                );
            },
        },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1); // Reset to the first page
    };

    return (
        <div>
            {!myOrdersLoading ? (
                <div className="flex min-h-screen bg-white text-gray-900 mt-10 overflow-hidden">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 overflow-auto">
                        <div className="lg:col-span-2 mt-4 mb-4">
                            <h4 className="text-gray-900 text-xl font-semibold">
                                Orders{' '}
                                <span className="text-gray-500 text-xl">
                                    {ordersArray.length}
                                </span>
                            </h4>
                        </div>

                        <Table3
                            rows={paginatedOrders}
                            columns={columns}
                            sortingState={sortingState}
                            onSortingChange={setSortingState}
                        />

                        <div className="flex justify-between items-center mt-4">
                            <div className="text-sm text-gray-500">
                                Page {currentPage} of {Math.ceil(ordersArray.length / pageSize)}
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="pageSize" className="mr-2 text-sm text-gray-500">
                                    Show
                                </label>
                                <select
                                    id="pageSize"
                                    className="border border-gray-300 rounded-md text-sm"
                                    value={pageSize}
                                    onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="px-3 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50"
                                >
                                    &lt;
                                </button>
                                <button
                                    onClick={() =>
                                        handlePageChange(
                                            Math.min(currentPage + 1, Math.ceil(ordersArray.length / pageSize))
                                        )
                                    }
                                    disabled={currentPage === Math.ceil(ordersArray.length / pageSize)}
                                    className="px-3 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50"
                                >
                                    &gt;
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default Order;
