import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { classNames } from '../components/shared/Utils';
import { requestOrganizerEvents, requestOrganizerDuplicateEvent } from '../store/actions';
import Loader from '../components/Loader';

const MyEvents = () => {
    const [loading, setLoading] = useState(true);
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownOpenId, setDropdownOpenId] = useState(null); // State to track which dropdown is open
    // const dropdownRef = useRef(null); // Ref for dropdown container
    const dropdownRefs = useRef({}); // Ref to track each dropdown
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const organizationDetails = useSelector(state => state.organizerDetails.orgDetails);
    const organizationEvents = useSelector(state => state.organizerEvents.orgEvents);
    const organizationEventsLoading = useSelector(state => state.organizerEvents.loading);
    const organizationDuplicateEvents = useSelector(state => state.organizerDuplicateEvent.event);
    const organizationDuplicateEventsLoading = useSelector(state => state.organizerDuplicateEvent.loading);

    useEffect(() => {
        const loadOrgEvents = async () => {
            if (loading && organizationDetails && organizationDetails.organizationSettings.basicSettings.orgId && !organizationDuplicateEventsLoading) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestOrganizerEvents(organizationDetails.organizationSettings.basicSettings.orgId, accessToken));
                setLoading(false);
            }
        };
        loadOrgEvents();
    }, [loading, getAccessTokenSilently, dispatch, organizationDetails, organizationDuplicateEventsLoading]);

    // const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleDropdown = (id) => {
        setDropdownOpenId(dropdownOpenId === id ? null : id); // Toggle the specific dropdown
    };

    const handleDuplicateClick = async (e, orgId, eventId) => {
        e.preventDefault();  // Prevent default behavior of the link
        if (!loading) {  // Ensure we are not dispatching while loading
            try {
                setDropdownOpenId(null);  // Close the dropdown

                const payload = {
                    "event_id": eventId,
                    "org_id": orgId
                };

                // Get access token
                const accessToken = await getAccessTokenSilently();

                // Dispatch the action to duplicate the event
                dispatch(requestOrganizerDuplicateEvent(payload, accessToken));

                // Set loading to true to indicate that the request is in progress
                setLoading(true);
            } catch (error) {
                console.error("Error fetching access token or dispatching event duplication:", error);
            }
        }
    };



    // Effect to handle clicks outside of the dropdown
    useEffect(() => {

        const handleClickOutside = (event) => {
            const openDropdown = dropdownRefs.current[dropdownOpenId];
            if (openDropdown && !openDropdown.contains(event.target)) {
                setDropdownOpenId(null); // Close the dropdown if clicked outside
            }
        };

        if (dropdownOpenId) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpenId]);

    const StatusPill = (value) => {
        let status = value ? value.toLowerCase() : "unknown";
        if (status.startsWith("live")) {
            status = "On Sale";
        }
        if (status.startsWith("started")) {
            status = "live";
        }
        return (
            <span className={classNames("px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("live") ? "pulsate-live" : null,
                status.startsWith("canceled") ? "bg-[#D439001A] text-[#D43900]" : null,
                status.startsWith("draft") ? "bg-[#008BBF1A] text-[#003863]" : null,
                status.startsWith("ended") ? "bg-[#F7911D26] text-[#474B55]" : null,
                status.startsWith("On Sale") ? "bg-[#5295351A] text-[#3E7128]" : null
            )}>
                {status}
            </span>
        );
    };

    if (!organizationDetails && !loading) {
        return <Loader />;
    }

    return (
        <div className="p-4 min-h-screen">
            <div className="flex items-center space-y-0 flex-row m-4 mt-8 border-b pb-6 justify-between">
                <h1 className="text-2xl font-semibold whitespace-nowrap">My Events</h1>
                <div className="w-44">
                    <Link to={`/organizer/${organizationDetails.organizationSettings.basicSettings.orgId}/create-event`}
                        className='flex w-full py-3 bg-[#83c342d5] border-none justify-center my-4 text-white text-sm font-semibold leading-tight rounded shadow-md hover:opacity-90 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:opacity-90 active:shadow-lg transition duration-150 ease-in-out'>
                        Create Event
                    </Link>
                </div>
            </div>

            {!loading && !organizationEventsLoading ? (
                organizationEvents.length !== 0 ? (
                    <div className="sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-4 mx-6">
                        {organizationEvents.map((item, index) => (
                            <div className="sm:max-w-sm bg-white border border-gray-200 shadow rounded-lg flex flex-col" key={index} data-event-name={item.title}>
                                <img className="w-full object-cover rounded-t-lg h-40" src={item.img_url} alt={item.title} />
                                <div className="p-5 flex flex-col flex-grow">
                                    <div className='flex justify-between'>
                                        <h5 className="flex-1 mb-2 text-xl font-bold tracking-tight text-gray-900 truncate">
                                            {item.title}
                                        </h5>
                                        <span className='flex-none'>
                                            {StatusPill(item.status)}
                                        </span>
                                    </div>

                                    <p className="text-gray-700 text-sm flex-grow">{item.short_description}</p>

                                    <div className="relative flex justify-end mt-auto">
                                        <button
                                            onClick={() => toggleDropdown(item.id)}
                                            className="text-gray-500 p-1.5 inline-flex items-center text-sm font-medium text-center border-none bg-white rounded-lg hover:bg-gray-100"
                                            type="button"
                                            data-toggle="dropdown"
                                            data-event-name={item.title}
                                        >
                                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                                                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                                            </svg>
                                        </button>

                                        <div
                                            ref={el => dropdownRefs.current[item.id] = el}
                                            id="dropdown"
                                            className={`absolute right-0 top-full mt-1 z-50 ${dropdownOpenId === item.id ? 'block' : 'hidden'
                                                } text-base list-none bg-white divide-y divide-gray-100 shadow-lg w-44 rounded-md transform-gpu`}
                                        >

                                            <ul className="py-2" aria-labelledby="dropdownButton">
                                                <li>
                                                    <Link to={`/organizer/${organizationDetails.organizationSettings.basicSettings.orgId}/events/${item.id}/edit`}
                                                        onClick={() => setDropdownOpenId(null)}
                                                        data-action="edit"
                                                        data-event-name={item.title}
                                                    >
                                                        <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                            Edit
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/events/${item.id}/preview`}
                                                        target='_blank'
                                                        onClick={() => setDropdownOpenId(null)}
                                                        data-action="preview"
                                                        data-event-name={item.title}
                                                    >
                                                        <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                            Preview
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/organizer/${organizationDetails.organizationSettings.basicSettings.orgId}/events/${item.id}/scan`}
                                                        onClick={() => setDropdownOpenId(null)}
                                                        data-action="scan"
                                                        data-event-name={item.title}
                                                    >
                                                        <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                            Scan
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`#`}
                                                        onClick={(e) => handleDuplicateClick(e, organizationDetails.organizationSettings.basicSettings.orgId, item.id)}
                                                        data-action="duplicate"
                                                        data-event-name={item.title}
                                                    >
                                                        <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                            Duplicate
                                                        </div>
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                    <Link to={`#`} target='_blank'>
                                                        <div className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100">
                                                            Delete
                                                        </div>
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        ))}
                    </div >
                ) : (
                    <div className="flex h-[45rem]">
                        <div className="m-auto">
                            <div className='flex flex-col items-center text-slate-500'>
                                <span className='font-semibold text-2xl mb-4'>No events found</span>
                                <span>You don't have any events, create an event to start engaging with customers</span>
                            </div>
                        </div>
                    </div>
                )
            ) : <Loader />}
        </div >
    );
};

export default MyEvents;
