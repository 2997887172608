import React from 'react';

export const ImageUpload = ({ value, onChange, label }) => {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => onChange(reader.result);
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="flex flex-col">
            {label && (
                <label className="text-left block text-sm font-medium text-gray-900 mb-2">
                    {label}
                </label>
            )}
            <div className="relative h-32 w-32">
                <div className="h-full w-full rounded-full bg-gray-100 overflow-hidden">
                    {value ? (
                        <img src={value} alt="Upload preview" className="h-full w-full object-cover" />
                    ) : (
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    )}
                </div>
                <label className="absolute bottom-0 left-0 right-0 text-center bg-black bg-opacity-50 text-white text-sm py-1 cursor-pointer">
                    Upload
                    <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </label>
            </div>
        </div>
    );
};