// TabPanel.jsx
import React from 'react';

export const TabPanel = ({ active, children }) => {
    if (!active) return null;

    return (
        <div className="p-4">
            {children}
        </div>
    );
};