import React, { useState, useEffect } from 'react';
import ScrollToTop from './utils/ScrollToTop';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import OrganizerSideNav from './OrganizerSideNav';
import CreateOrganizerModal from './CreateOrganizerModal';
import { requestUserOrganizaions, setSelectedOrganization } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './Loader';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';


const OrgWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // For small screens

    const orgs = useSelector((state) => state.userOrgs.orgs.data);
    const orgsLoading = useSelector((state) => state.userOrgs.loading);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);
    const selectedOrgLoading = useSelector((state) => state.selectedOrganization.loading);

    useEffect(() => {
        const getOrganizations = async () => {
            if (loading) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestUserOrganizaions(accessToken));
                setLoading(false);
            }
        };

        getOrganizations();
    }, [loading, getAccessTokenSilently, dispatch]);

    useEffect(() => {
        if (!loading && !orgsLoading && orgs && orgs.length > 0) {
            if (location.pathname === '/organizer') {
                dispatch(setSelectedOrganization(orgs[orgs.length - 1].org_id));
            } else if (location.pathname.includes('/organizer/')) {
                const parts = location.pathname.split('/');
                dispatch(setSelectedOrganization(parts[2]));
            }
        }
    }, [loading, orgsLoading, location.pathname, dispatch, orgs]);

    useEffect(() => {
        if (!loading && !selectedOrgLoading && selectedOrg !== null) {
            if (location.pathname === '/organizer') {
                navigate(`/organizer/${selectedOrg}/dashboard`);
            }
        }
    }, [selectedOrgLoading, selectedOrg, location.pathname, navigate]);

    return (
        <>
            {!loading && !orgsLoading && orgs !== null && selectedOrg !== null ? (
                <div className="h-screen bg-gray-50 flex">
                    <ScrollToTop />

                    {/* Sidebar */}
                    <aside
                        className={`fixed inset-y-0 left-0 bg-white border-r border-gray-200 transition-all duration-300 z-30
                            ${isSidebarOpen ? "translate-x-0 w-64" : "-translate-x-full w-64"} 
                            lg:translate-x-0 ${isSidebarOpen ? "lg:w-64" : "lg:w-16"}`}
                    >
                        <div className="h-full relative">
                            {/* Toggle Button (Works for both large & small screens) */}
                            <button
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                className="absolute -right-6 top-1/2 transform -translate-y-1/2 
                                    bg-white border border-gray-200 rounded-full p-1.5 
                                    shadow-md hover:bg-gray-50 transition-colors z-40"
                                aria-label={isSidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
                            >
                                {isSidebarOpen ? (
                                    <MdKeyboardDoubleArrowLeft className="h-8 w-8 text-gray-600" />
                                ) : (
                                    <MdKeyboardDoubleArrowRight className="h-8 w-8 text-gray-600" />
                                )}
                            </button>

                            <OrganizerSideNav isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                        </div>
                    </aside>

                    {/* Overlay for small screens */}
                    {isSidebarOpen && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm transition-opacity z-20 lg:hidden"
                            onClick={() => setIsSidebarOpen(false)}
                        ></div>
                    )}

                    {/* Main Content */}
                    <main
                        className={`flex-1 overflow-auto bg-gray-50 transition-all duration-300
                        ${isSidebarOpen ? "lg:ml-64" : "lg:ml-16"} 
                        ml-0`}
                    >
                        <div className="container mx-auto px-6 py-8">
                            <Outlet />
                        </div>
                    </main>

                </div>
            ) : (!orgsLoading && orgs?.length === 0) ? (
                <div className="h-full">
                    <CreateOrganizerModal />
                </div>
            ) : (
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Loader />
                    </div>
                </div>
            )}
        </>
    );

};

export default OrgWrapper;
