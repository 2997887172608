// // 'use client';
// import React, { useEffect, useState, useRef } from 'react'
// import UserAvatar from './UserAvatar';
// import { convertUnixToHumanDate, convertToHumanDate, convertToRelativeDate, formatToShortDateTime } from './common/dateUtils';
// import { classNames } from './shared/Utils'
// import { getCurrencyFormat } from './common/currencyUtils';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableHead,
//     TableHeaderCell,
//     TableRow,
// } from '@tremor/react';



// const Table3 = ({ tableType, headers, rows, orderSelected }) => {

//     const [showActionMenus, setShowActionMenus] = useState(Array(rows.length).fill(false));
//     const submenuRef = useRef(null);

//     const closeAllSubmenus = () => {
//         setShowActionMenus(Array(rows.length).fill(false));
//     };

//     const tableColumnHeader = (name) => {
//         return (
//             <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong text-center">
//                 {name}
//             </TableHeaderCell>
//         )
//     }

//     const orgUsersRow = (row) => {
//         return (
//             <tr class="transition-all hover:bg-gray-100 hover:shadow-lg">
//                 {/* <td class="px-6 py-4 whitespace-nowrap">
//                     <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{row.order_id}</td>
//                 </td> */}
//                 {/* <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.first_name}
//                 </td>
//                 <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.last_name}
//                 </td> */}
//                 <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.email}
//                 </td>
//                 <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.roles[0].name}
//                 </td>
//                 <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.status}
//                 </td>
//                 <td class="px-6 py-2 whitespace-nowrap">

//                     <button
//                         class="inline-flex items-center p-2 text-sm font-medium text-center border-none  text-gray-900 bg-white rounded-lg hover:bg-gray-100 "
//                         type="button"
//                     // onClick={() => toggleActionMenu(index)}
//                     >
//                         <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
//                             <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
//                         </svg>
//                     </button>
//                 </td>
//             </tr>
//         )
//     }

//     const orgRolesRow = (row) => {
//         return (
//             <TableRow class="transition-all hover:bg-gray-100 hover:shadow-lg">
//                 <TableCell class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.name}
//                 </TableCell>
//                 <TableCell class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.description}
//                 </TableCell>
//                 <TableCell class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {row.user_count}
//                 </TableCell>
//             </TableRow>
//         )
//     }

//     const latestTransactionRow = (row) => {
//         return (
//             <TableRow class=" even:bg-white even:dark:bg-dark-tremor-background-muted">
//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap">
//                     <TableCell >
//                         {/* {row.order_id} */}
//                         #{String(row.order_id).padStart(6, '0')}
//                     </TableCell>
//                 </TableCell>
//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
//                     {row.first_name}. {row.last_name}
//                 </TableCell>
//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
//                     {getTotal(row.tickets)}
//                 </TableCell>
//                 <TableCell class="px-6 py-0 content-center text-sm text-gray-500 whitespace-nowrap text-left">{convertToRelativeDate(row.order_date)}</TableCell>
//             </TableRow>
//         )
//     }

//     const latestPayoutRow = (row) => {
//         return (
//             <TableRow class=" even:bg-white even:dark:bg-dark-tremor-background-muted">
//                 <TableCell class="px-6 py-0 content-center text-sm text-gray-500 whitespace-nowrap text-left">{formatToShortDateTime(row.payout_date)}</TableCell>
//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
//                     Bank Of America ****1876
//                 </TableCell>
//                 <TableCell className="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
//                     {row.amount ? `${getCurrencyFormat(row.amount, row.currency)} ${row.currency}` : '-'}
//                 </TableCell>

//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
//                     {row.status}
//                 </TableCell>
//             </TableRow>
//         )
//     }

//     const consolidateRow = () => {

//     }

//     const orderDetailsRow = (row) => {
//         return (
//             <TableRow class=" even:bg-white even:dark:bg-dark-tremor-background-muted">
//                 <TableCell class="px-0 py-0 content-center whitespace-nowrap overflow-hidden overflow-ellipsis min-w-8 max-w-8">
//                     <TableCell >
//                         {row.title}
//                     </TableCell>
//                 </TableCell>
//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500 text-center w-3">
//                     {row.qty}
//                 </TableCell>
//                 <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500 text-right w-6">
//                     {parseFloat(row.unitPrice) > 0.00 ? getCurrencyFormat(row.unitPrice, row.currency) : "-"}
//                 </TableCell>
//                 <TableCell class="px-6 py-0 content-center text-sm text-gray-500 whitespace-nowrap text-right max-w-6">
//                     {parseFloat(row.subtotal) > 0.00 ? getCurrencyFormat(row.subtotal, row.currency) : "-"}
//                 </TableCell>
//             </TableRow>
//         )
//     }

//     const StatusPill = (value) => {
//         const status = value ? value.toLowerCase() : "unknown";

//         // const finalStatus = status.startsWith("completed") ? 'completed' : status

//         return (
//             <span
//                 className={
//                     classNames(
//                         "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
//                         status.startsWith("completed") ? "bg-[#d9fbd0] text-[#3E7128] border border-[#bee8b4]" : null,
//                         // status.startsWith("refunded") ? "bg-[#ffefca] text-[#bc3803] bg-opacity-50" : null,
//                         status.startsWith("refunded") ? "bg-[#e3e6ed] text-[#141824] border border-[#cbd0dd]  bg-opacity-50" : null,
//                         // status.startsWith("") ? "bg-[#c7ebff] text-[#005585] border border-[#96d9ff]" : null,
//                     )
//                 }
//             >
//                 {status}
//             </span>
//         );
//     };

//     const toggleActionMenu = (index) => {

//         closeAllSubmenus();

//         const newShowActionMenus = [...showActionMenus];
//         newShowActionMenus[index] = !newShowActionMenus[index];
//         setShowActionMenus(newShowActionMenus);
//     };

//     const orgOrdersRow = (item, index) => {
//         return (
//             <TableRow
//                 key={item.workspace}
//                 className="even:bg-tremor-background-muted even:dark:bg-dark-tremor-background-muted"
//             >
//                 <TableCell className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
//                     #{String(item.order_id).padStart(6, '0')}
//                 </TableCell>
//                 <TableCell>
//                     {item.event_name}
//                 </TableCell>
//                 <TableCell>
//                     <td class="px-6 py-0 whitespace-nowrap text-sm text-gray-500 flex items-center">
//                         <UserAvatar firstName={item.first_name} lastName={item.last_name} />
//                         <span className='pl-3'>
//                             {item.email}
//                         </span>
//                     </td>
//                 </TableCell>
//                 <TableCell>
//                     {StatusPill(item.order_status)}
//                 </TableCell>
//                 <TableCell>
//                     {
//                         item.price ?
//                             <>
//                                 ${item.price} {item.currency}
//                             </>
//                             :
//                             <>
//                                 Free
//                             </>
//                     }
//                 </TableCell>
//                 <TableCell className="text-right">
//                     {convertToHumanDate(item.ordered_date)}
//                 </TableCell>
//                 <TableCell className="text-right">
//                     <button
//                         class="inline-flex items-center p-2 text-sm font-medium text-center border-none  text-gray-900 bg-white rounded-lg hover:bg-gray-100 "
//                         type="button"
//                         onClick={() => toggleActionMenu(index)}>
//                         <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
//                             <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
//                         </svg>
//                     </button>

//                     {
//                         showActionMenus[index] ?

//                             <>
//                                 < div
//                                     ref={submenuRef}
//                                     class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute -ml-[6%]">
//                                     <ul class="py-2 text-sm text-gray-700" >
//                                         <li className='hover:bg-gray-50 hover:cursor-pointer disa'>
//                                             {item.order_status.toLowerCase() === 'refunded' ? (
//                                                 <div
//                                                     className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2 opacity-50 cursor-not-allowed"
//                                                 >
//                                                     Refund
//                                                 </div>
//                                             ) : (
//                                                 <div
//                                                     onClick={() => orderSelected(item)}
//                                                     className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2"
//                                                 >
//                                                     Refund
//                                                 </div>
//                                             )}
//                                         </li>

//                                     </ul>
//                                 </div>
//                             </> : <></>
//                     }
//                 </TableCell>
//             </TableRow>
//         )
//     }

//     const getTotal = (row) => {
//         let total = 0;

//         for (const ticket of row) {
//             total += ticket.count;
//         }

//         return total;
//     }


//     return (
//         <>
//             <Table className="mt-8 w-full">
//                 <TableHead>
//                     <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
//                         {headers.map((item, index) => (
//                             tableColumnHeader(item)
//                         ))}
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {
//                         rows.length > 0 &&

//                         rows.map((item, index) => {
//                             switch (tableType) {
//                                 case "latestPurchases":
//                                     return latestTransactionRow(item);
//                                 case "orgOrders":
//                                     return orgOrdersRow(item, index);
//                                 case "orgUsers":
//                                     return orgUsersRow(item);
//                                 case "orgRoles":
//                                     return orgRolesRow(item);
//                                 case "orderDetails":
//                                     return orderDetailsRow(item);
//                                 case "latestPayoutTransactions":
//                                     return latestPayoutRow(item);
//                                 default:
//                                     return null; //paymentTransactionRow(item);
//                             }
//                         })

//                     }
//                 </TableBody>
//             </Table>

//             {
//                 rows.length == 0 &&

//                 <div className='p-6 bg-white'>
//                     <div class="tremor-Flex-root flex flex-row justify-center items-center w-full h-full border border-dashed rounded-tremor-default border-tremor-border bg-white dark:border-tdark-remor-border min-h-[22rem]">
//                         <p class="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
//                             No data
//                         </p>
//                     </div>
//                 </div>
//             }

//         </>
//     );
// }

// export default Table3;







// import React from 'react';
// import {
//     flexRender,
//     useReactTable,
//     getCoreRowModel,
//     getSortedRowModel,
//     getFilteredRowModel,
// } from '@tanstack/react-table';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableHead,
//     TableHeaderCell,
//     TableRow,
// } from '@tremor/react';

// const Table3 = ({ rows, columns, sortingState, onSortingChange }) => {
//     const table = useReactTable({
//         data: rows,
//         columns,
//         state: { sorting: sortingState },
//         onSortingChange,
//         getCoreRowModel: getCoreRowModel(),
//         getSortedRowModel: getSortedRowModel(),
//         getFilteredRowModel: getFilteredRowModel(),
//     });

//     return (
//         <Table className="mt-8 w-full">
//             <TableHead>
//                 {table.getHeaderGroups().map((headerGroup) => (
//                     <TableRow key={headerGroup.id}>
//                         {headerGroup.headers.map((header) => (
//                             <TableHeaderCell
//                                 key={header.id}
//                                 onClick={header.column.getToggleSortingHandler()}
//                                 className="cursor-pointer text-tremor-content-strong dark:text-dark-tremor-content-strong text-center"
//                             >
//                                 <div className="flex items-center justify-between">
//                                     {flexRender(
//                                         header.column.columnDef.header,
//                                         header.getContext()
//                                     )}
//                                     {{
//                                         asc: '↑',
//                                         desc: '↓',
//                                     }[header.column.getIsSorted()] || null}
//                                 </div>
//                             </TableHeaderCell>
//                         ))}
//                     </TableRow>
//                 ))}
//             </TableHead>
//             <TableBody>
//                 {table.getRowModel().rows.length > 0 ? (
//                     table.getRowModel().rows.map((row) => (
//                         <TableRow key={row.id}>
//                             {row.getVisibleCells().map((cell) => (
//                                 <TableCell key={cell.id}>
//                                     {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     ))
//                 ) : (
//                     <TableRow>
//                         <TableCell colSpan={columns.length} className="text-center">
//                             No data
//                         </TableCell>
//                     </TableRow>
//                 )}
//             </TableBody>
//         </Table>
//     );
// };

// export default Table3;





import React, { useState, useMemo } from 'react';
import {
    flexRender,
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { classNames } from './shared/Utils'


const Table3 = ({
    rows,
    columns,
    sortingState,
    onSortingChange,
    initialState = {},
    disableFeatures = false,
    onRowClick,
}) => {
    const [sorting, setSorting] = useState(sortingState || []);
    const [filtering, setFiltering] = useState(''); // Global filter
    const [pageSize, setPageSize] = useState(initialState?.pagination?.pageSize || 10);
    const [currentPage, setCurrentPage] = useState(0); // Current page index

    const filteredRows = useMemo(() => {
        if (disableFeatures) {
            return rows;
        }
        return rows.filter((row) => {
            return Object.values(row).some((value) =>
                String(value).toLowerCase().includes(filtering.toLowerCase())
            );
        });
    }, [rows, filtering, disableFeatures]);

    const table = useReactTable({
        data: filteredRows,
        columns,
        state: {
            sorting,
            globalFilter: filtering,
            pagination: {
                pageSize,
                pageIndex: currentPage,
            },
        },
        onSortingChange: (state) => {
            setSorting(state);
            if (onSortingChange) onSortingChange(state);
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            sorting: initialState?.sorting || [],
            pagination: {
                pageSize: pageSize,
                pageIndex: 0,
            },
        },
    });

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="w-full">
            {!disableFeatures && (
                <div className="flex justify-between items-center mb-4">
                    <input
                        type="text"
                        value={filtering}
                        onChange={(e) => setFiltering(e.target.value)}
                        placeholder="🔍 Search"
                        className="border border-gray-300 rounded-md px-4 py-2 w-full md:w-1/3"
                    />
                </div>
            )}

            <table className="w-full border border-gray-200 rounded-lg shadow-sm">
                <thead className="">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    className={`border-b px-4 py-2 text-left text-sm font-medium text-black ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                                        }`}
                                >
                                    <div
                                        className={classNames(
                                            header.column.columnDef.enableSorting === true
                                                ? 'flex items-center justify-between gap-2 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted'
                                                : header.column.columnDef.meta.align,
                                            'rounded-tremor-default px-3 py-1.5'
                                        )}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getCanSort() && (
                                            <div className="-space-y-2">
                                                <RiArrowUpSLine
                                                    className={classNames(
                                                        'h-4 w-4 text-tremor-content-strong dark:text-dark-tremor-content-strong',
                                                        header.column.getIsSorted() === 'desc' ? 'opacity-30' : ''
                                                    )}
                                                    aria-hidden={true}
                                                />
                                                <RiArrowDownSLine
                                                    className={classNames(
                                                        'h-4 w-4 text-tremor-content-strong dark:text-dark-tremor-content-strong',
                                                        header.column.getIsSorted() === 'asc' ? 'opacity-30' : ''
                                                    )}
                                                    aria-hidden={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr
                            key={row.id}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => onRowClick && onRowClick(row.original)}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    className="border-b px-4 py-4 text-sm text-gray-500"
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {!disableFeatures && (
                <div className="flex justify-between items-center mt-4">
                    {/* Rows per page */}
                    <div>
                        <span className="text-gray-800">Rows per page:</span>
                        <select
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                            className="border border-gray-300 rounded-md px-2 py-1 m-2"
                        >
                            {[10, 25, 50, 100].map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Pagination Controls */}
                    <div className='flex'>
                        <button
                            disabled={!table.getCanPreviousPage()}
                            onClick={() => table.setPageIndex(0)}
                            className="items-center border-inherit hover:bg-slate-100 justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground hidden h-8 w-8 p-0 lg:flex">
                            <span className="sr-only">Go to first page</span>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                                <path d="M6.85355 3.85355C7.04882 3.65829 7.04882 3.34171 6.85355 3.14645C6.65829 2.95118 6.34171 2.95118 6.14645 3.14645L2.14645 7.14645C1.95118 7.34171 1.95118 7.65829 2.14645 7.85355L6.14645 11.8536C6.34171 12.0488 6.65829 12.0488 6.85355 11.8536C7.04882 11.6583 7.04882 11.3417 6.85355 11.1464L3.20711 7.5L6.85355 3.85355ZM12.8536 3.85355C13.0488 3.65829 13.0488 3.34171 12.8536 3.14645C12.6583 2.95118 12.3417 2.95118 12.1464 3.14645L8.14645 7.14645C7.95118 7.34171 7.95118 7.65829 8.14645 7.85355L12.1464 11.8536C12.3417 12.0488 12.6583 12.0488 12.8536 11.8536C13.0488 11.6583 13.0488 11.3417 12.8536 11.1464L9.20711 7.5L12.8536 3.85355Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>
                        <button
                            disabled={!table.getCanPreviousPage()}
                            onClick={() => table.previousPage()}
                            className='inline-flex border-inherit hover:bg-slate-100 items-center justify-center whitespace-nowrap rounded-md text-3xl font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 w-8 p-0'>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                                <path d="M8.84182 3.13514C9.04327 3.32401 9.05348 3.64042 8.86462 3.84188L5.43521 7.49991L8.86462 11.1579C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0535 8.32394 12.0433 8.13508 11.8419L4.38508 7.84188C4.20477 7.64955 4.20477 7.35027 4.38508 7.15794L8.13508 3.15794C8.32394 2.95648 8.64036 2.94628 8.84182 3.13514Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>

                        <button
                            disabled={!table.getCanNextPage()}
                            onClick={() => table.nextPage()}
                            className="inline-flex border-inherit hover:bg-slate-100 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 w-8 p-0">
                            <span className="sr-only">Go to next page</span>
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4">
                                <path d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>
                        <button
                            disabled={!table.getCanNextPage()}
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            className="items-center border-inherit hover:bg-slate-100 justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground hidden h-8 w-8 p-0 lg:flex">
                            <span className="sr-only">Go to last page</span>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                                <path d="M2.14645 11.1464C1.95118 11.3417 1.95118 11.6583 2.14645 11.8536C2.34171 12.0488 2.65829 12.0488 2.85355 11.8536L6.85355 7.85355C7.04882 7.65829 7.04882 7.34171 6.85355 7.14645L2.85355 3.14645C2.65829 2.95118 2.34171 2.95118 2.14645 3.14645C1.95118 3.34171 1.95118 3.65829 2.14645 3.85355L5.79289 7.5L2.14645 11.1464ZM8.14645 11.1464C7.95118 11.3417 7.95118 11.6583 8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L12.8536 7.85355C13.0488 7.65829 13.0488 7.34171 12.8536 7.14645L8.85355 3.14645C8.65829 2.95118 8.34171 2.95118 8.14645 3.14645C7.95118 3.34171 7.95118 3.65829 8.14645 3.85355L11.7929 7.5L8.14645 11.1464Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Table3;