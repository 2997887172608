// import * as React from "react"
// import { cva } from "class-variance-authority"
// // import { cn } from "@/lib/utils"
// import { classNames } from "./Utils"


// const alertVariants = cva(
//     "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
//     {
//         variants: {
//             variant: {
//                 default: "bg-background text-foreground",
//                 destructive:
//                     "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
//             },
//         },
//         defaultVariants: {
//             variant: "default",
//         },
//     }
// )

// const Alert = React.forwardRef(({ className, variant, ...props }, ref) => (
//     <div
//         ref={ref}
//         role="alert"
//         className={classNames(alertVariants({ variant }), className)}
//         {...props}
//     />
// ))
// Alert.displayName = "Alert"

// const AlertTitle = React.forwardRef(({ className, ...props }, ref) => (
//     <h5
//         ref={ref}
//         className={classNames("mb-1 font-medium leading-none tracking-tight", className)}
//         {...props}
//     />
// ))
// AlertTitle.displayName = "AlertTitle"

// const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
//     <div
//         ref={ref}
//         className={classNames("text-sm [&_p]:leading-relaxed", className)}
//         {...props}
//     />
// ))
// AlertDescription.displayName = "AlertDescription"

// export { Alert, AlertTitle, AlertDescription }









// import * as React from "react"
// import { cva } from "class-variance-authority"
// import { classNames } from "./Utils"

// const alertVariants = {
//     error: 'bg-red-100 border-red-500 text-red-900',
//     success: 'bg-green-100 border-green-500 text-green-900',
//     warning: 'bg-yellow-100 border-yellow-500 text-yellow-900',
//     info: 'bg-blue-100 border-blue-500 text-blue-900'
// };

// const Alert = React.forwardRef(({
//     className,
//     variant = 'info',
//     title,
//     message,
//     onClose,
//     autoHideDuration = 9000000, //5000,
//     ...props
// }, ref) => {
//     React.useEffect(() => {
//         if (autoHideDuration) {
//             const timer = setTimeout(() => {
//                 onClose?.();
//             }, autoHideDuration);
//             return () => clearTimeout(timer);
//         }
//     }, [autoHideDuration, onClose]);

//     return (
//         <div className="fixed inset-x-0 top-[15%] z-50 flex justify-center">
//             <div
//                 ref={ref}
//                 role="alert"
//                 className={classNames(
//                     "max-w-md rounded-lg border p-4 shadow-lg",
//                     "animate-in fade-in slide-in-from-top-2",
//                     alertVariants[variant],
//                     className
//                 )}
//                 {...props}
//             >
//                 <div className="flex justify-between">
//                     {title && (
//                         <h5 className="font-medium">{title}</h5>
//                     )}
//                     {onClose && (
//                         <button
//                             onClick={onClose}
//                             className="ml-4 hover:opacity-70"
//                         >
//                             ×
//                         </button>
//                     )}
//                 </div>
//                 <div className="mt-1 text-sm">
//                     {message}
//                 </div>
//             </div>
//         </div>
//     )
// });


// export { Alert }





import React, { useEffect } from 'react';
import {
    HiXMark,
    HiCheckCircle,
    HiExclamationCircle,
    HiInformationCircle,
    HiExclamationTriangle
} from "react-icons/hi2";

const variants = {
    success: {
        background: 'bg-green-50',
        border: 'border-green-200',
        text: 'text-green-800',
        icon: HiCheckCircle,
        iconColor: 'text-green-400',
    },
    error: {
        background: 'bg-red-50',
        border: 'border-red-200',
        text: 'text-red-800',
        icon: HiExclamationCircle,
        iconColor: 'text-red-400',
    },
    warning: {
        background: 'bg-yellow-50',
        border: 'border-yellow-200',
        text: 'text-yellow-800',
        icon: HiExclamationTriangle,
        iconColor: 'text-yellow-400',
    },
    info: {
        background: 'bg-blue-50',
        border: 'border-blue-200',
        text: 'text-blue-800',
        icon: HiInformationCircle,
        iconColor: 'text-blue-400',
    },
};

const Alert = React.forwardRef(({
    variant = 'info',
    title,
    message,
    onClose,
    className = '',
    autoHideDuration = 5000,
    ...props
}, ref) => {
    const styles = variants[variant];
    const Icon = styles.icon;

    useEffect(() => {
        if (autoHideDuration) {
            const timer = setTimeout(() => {
                onClose?.();
            }, autoHideDuration);
            return () => clearTimeout(timer);
        }
    }, [autoHideDuration, onClose]);

    return (
        <div className="fixed inset-x-0 top-[15%] z-50 flex justify-center">
            <div
                ref={ref}
                role="alert"
                className={`
          max-w-md flex items-start gap-3 p-4 rounded-lg border shadow-lg
          animate-in fade-in slide-in-from-top-2
          ${styles.background} ${styles.border} ${className}
        `}
                {...props}
            >
                <Icon className={`w-5 h-5 ${styles.iconColor} mt-0.5 flex-shrink-0`} />

                <div className="flex-1 min-w-0">
                    {title && (
                        <h3 className={`font-semibold mb-1 ${styles.text}`}>
                            {title}
                        </h3>
                    )}
                    <p className={`text-sm ${styles.text}`}>
                        {message}
                    </p>
                </div>

                {onClose && (
                    <button
                        onClick={onClose}
                        className={`p-1 hover:bg-opacity-20 hover:bg-gray-900 rounded ${styles.text} flex-shrink-0 border-none`}
                    >
                        <HiXMark className="w-4 h-4" />
                        <span className="sr-only">Close</span>
                    </button>
                )}
            </div>
        </div>
    );
});

export { Alert }