import React, { useEffect, useState } from 'react';
import OrganizerSideNav from '../components/OrganizerSideNav';
import CardMetric from '../components/dashboard/CardMetric';
import { Select, SelectItem } from "@tremor/react";
// import { Select, SelectItem, SelectTrigger, SelectValue } from "@tremor/react";
import TicketSalesProgressBar from '../components/dashboard/TicketSalesProgressBar';
import Table3 from '../components/Table3';
import RevenueAreaChart from '../components/dashboard/RevenueAreaChart';
import DailyTicketSales from '../components/dashboard/DailyTicketSales';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader';
import { Title } from "@tremor/react";
import ShareBanner from '../components/dashboard/ShareBanner';
import { getCurrencyFormat } from '../components/common/currencyUtils';

import { requestDashboardMetrics } from '../store/actions/index';
import { Dropdown } from '../components/shared/Dropdown'


const Dashboard = () => {
    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const organizationDetailsLoading = useSelector((state) => state.organizerDetails.loading);

    const organizationEvents = useSelector((state) => state.organizerEvents.orgEvents);
    const organizationEventsLoading = useSelector((state) => state.organizerEvents.loading);

    const dashboardMetrics = useSelector((state) => state.dashboardMetrics.dashboardMetrics);
    const dashboardMetricsLoading = useSelector((state) => state.dashboardMetrics.loading);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);

    const [eventId, setEventId] = useState('');
    const [sortingState, setSortingState] = useState([]);

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    // Set the default event ID
    useEffect(() => {
        if (organizationEvents.length !== 0) {
            setEventId(organizationEvents[organizationEvents.length - 1].id);
        }
    }, [organizationEvents]);

    // Fetch dashboard metrics
    useEffect(() => {
        const loadDashboard = async () => {
            if (eventId !== '') {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestDashboardMetrics(selectedOrg, eventId, accessToken));
            }
        };
        loadDashboard();
    }, [eventId]);

    // Define columns for Latest Transactions
    const latestPurchasesColumns = [
        {
            accessorKey: 'order_id',
            header: 'Order ID',
            enableSorting: false,
            meta: {
                align: 'text-left',
            },
            cell: (info) => `#${String(info.getValue()).padStart(6, '0')}`,
        },
        {
            accessorKey: 'customer',
            header: 'Customer',
            enableSorting: false,
            meta: {
                align: 'text-left',
            },
            cell: (info) => `${info.row.original.first_name} ${info.row.original.last_name}`,
        },
        {
            accessorKey: 'qty',
            header: 'Qty',
            enableSorting: false,
            meta: {
                align: 'text-left',
            },
            cell: (info) => getTotal(info.row.original.tickets),
        },
        {
            accessorKey: 'order_date',
            header: 'Order Date',
            enableSorting: false,
            meta: {
                align: 'text-left',
            },
            cell: (info) => new Date(info.getValue()).toLocaleDateString(),
        },
    ];

    const convertDataToDailyTicketSales = (dataSet) => {
        return dataSet.map(item => {
            const day = new Date(item.day).toLocaleDateString("en-US", { weekday: "short" });
            const metricsObj = {
                Day: day,
            };
            item.metrics.forEach(metric => {
                metricsObj[metric.title] = metric.tickets_sold;
            });
            return metricsObj;
        });
    };

    const getUniqueTitlesForDailyTicketSales = (dataSet) => {
        const uniqueTitles = new Set();
        dataSet.forEach(item => {
            item.metrics.forEach(metric => {
                uniqueTitles.add(metric.title);
            });
        });
        return Array.from(uniqueTitles);
    };

    const getTotal = (row) => {
        let total = 0;

        for (const ticket of row) {
            total += ticket.count;
        }

        return total;
    }

    return (
        dashboardMetricsLoading || organizationEventsLoading || organizationDetailsLoading || organizationDetails.length < 1 ? (
            <Loader />
        ) : (
            <div className="sm:p-4 min-h-screen">
                <div className="flex flex-col justify-between pb-6 space-y-4 border-b md:space-y-0 md:flex-row m-4 pt-8">
                    <div>
                        <h1 className="text-2xl font-semibold whitespace-nowrap">Dashboard</h1>
                    </div>
                    <Dropdown
                        options={organizationEvents} // Pass event objects with id, title, img_url
                        value={eventId} // Currently selected event ID
                        onChange={(e) => setEventId(e.target.value)} // Handle selection
                        name="eventDropdown"
                        buttonClassName="w-full sm:max-w-72 md:w-72"
                    />
                </div>


                {organizationEvents.length > 0 && (
                    <div className="m-4">
                        <ShareBanner event_id={eventId} />
                    </div>
                )}

                <div className="grid grid-cols-1 gap-5 mt-6 m-4 sm:grid-cols-2 lg:grid-cols-4">
                    <CardMetric
                        title="Gross Sales"
                        value={dashboardMetrics.total_ticket_revenue ? getCurrencyFormat(dashboardMetrics.total_ticket_revenue, organizationDetails.organizationSettings.basicSettings.region.defaultCurrency.toUpperCase()) : '-'}
                        currency={dashboardMetrics.currency || organizationDetails.organizationSettings.basicSettings.region.defaultCurrency.toUpperCase()}
                    />
                    <CardMetric
                        title="Net Sales"
                        value={dashboardMetrics.total_ticket_revenue ? getCurrencyFormat(dashboardMetrics.total_ticket_revenue, organizationDetails.organizationSettings.basicSettings.region.defaultCurrency.toUpperCase()) : '-'}
                        currency={dashboardMetrics.currency || organizationDetails.organizationSettings.basicSettings.region.defaultCurrency.toUpperCase()}
                    />
                    <CardMetric
                        title="Total Orders"
                        value={dashboardMetrics.num_of_orders || '-'}
                    />
                    <CardMetric
                        title="Tickets Sold"
                        value={dashboardMetrics.num_of_tickets_sold || '-'}
                    />
                </div>

                <div className="grid grid-cols-1 gap-5 lg:grid-cols-5 lg:gap-5 mt-6 m-4">
                    <div className="col-span-3 overflow-hidden rounded-lg ring-1 ring-tremor-ring">
                        <RevenueAreaChart
                            dataSet={dashboardMetrics.daily_total_tracker || []}
                            currency={organizationDetails.organizationSettings.basicSettings.region.defaultCurrency.toUpperCase()}
                        />
                    </div>
                    <div className="col-span-3 lg:col-span-2">
                        <DailyTicketSales
                            dataSet={convertDataToDailyTicketSales(dashboardMetrics.daily_breakdown || [])}
                            categories={getUniqueTitlesForDailyTicketSales(dashboardMetrics.daily_breakdown || [])}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-5 gap-5 mt-6 m-4">
                    <div className="col-span-3 rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                        <Title>Latest Transactions</Title>
                        <Table3
                            rows={dashboardMetrics.latest_orders || []}
                            columns={latestPurchasesColumns}
                            sortingState={sortingState}
                            onSortingChange={setSortingState}
                            disableFeatures={true}
                        />
                    </div>
                    <div className="col-span-3 lg:col-span-2">
                        <TicketSalesProgressBar
                            dataSet={dashboardMetrics.ticket_sales_breakdown || []}
                            eventName={dashboardMetrics.event_name || ""}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default Dashboard;
