import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import StepperControl from "./StepperControl";
import { getCurrencyFormat } from "./common/currencyUtils";
import Loader from '../components/Loader';
import { requestOrganizerPayout } from './../store/actions/index';


const PayoutModal = ({ onClose }) => {
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const initiatePayout = useSelector((state) => state.organizerPayouts.initiatePayout);
    const initiatePayoutLoading = useSelector((state) => state.organizerPayouts.loading);
    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const orgPaymentDetails = useSelector((state) => state.organizerPaymentDetails.orgPaymentDetails);

    const dispatch = useDispatch();

    const [amount, setAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1); // Step state to manage flow.
    const [isProcessing, setIsProcessing] = useState(false);



    useEffect(() => {
        const loadOrgPaymentDetails = async () => {
            if (loading && orgPaymentDetails) {
                setLoading(false);
                setMaxAmount(orgPaymentDetails.balance?.available?.[0]?.amount);
            }
        };

        loadOrgPaymentDetails();
    }, [loading, orgPaymentDetails]);


    const submitPayout = async () => {
        const accessToken = await getAccessTokenSilently();
        const payload = {
            "amount": amount,
        }
        dispatch(requestOrganizerPayout(organizationDetails.organizationSettings.basicSettings.orgId, payload, accessToken));
    }

    const closeModal = () => {
        onClose();
    };

    const handleSliderChange = (e) => {
        setAmount(e.target.value);
    };

    const handlePresetClick = (percentage) => {
        setAmount((maxAmount * percentage) / 100);
    };

    const handleNext = (direction) => {
        if (direction === "next") {
            if (step === 1) {
                setStep(2); // Move to confirmation step.
            } else if (step === 2) {
                handleConfirm(); // Process payout on confirm.
            }
        } else if (direction === "prev") {
            if (step > 1) {
                setStep(step - 1); // Move back to the previous step.
            }
        }
    };


    const handleConfirm = async () => {
        setIsProcessing(true);
        submitPayout();
    };

    useEffect(() => {
        if (!initiatePayoutLoading && initiatePayout && Object.keys(initiatePayout).length != 0 && isProcessing) {
            console.log(initiatePayout);
            setIsProcessing(false);
            setStep(3); // Move to success step.
        }
    }, [initiatePayout, initiatePayoutLoading]);

    const renderContent = () => {
        if (isProcessing) {
            return (
                <div className="flex justify-center items-center py-10">
                    <Loader /> {/* Show Loader while processing */}
                </div>
            );
        }

        switch (step) {
            case 1:
                return (
                    <>
                        <h2 className="text-xl font-semibold text-center text-gray-700 mb-6">
                            Select Payout Amount
                        </h2>
                        <div className="flex flex-col items-center">
                            <div className="my-6 text-center">
                                <div className="inline-block bg-lime-100 text-lime-600 px-6 py-3 rounded-lg shadow-md">
                                    <p className="text-3xl font-bold">
                                        {getCurrencyFormat(
                                            amount,
                                            orgPaymentDetails.balance?.available?.[0]?.currency.toUpperCase(),
                                            true
                                        )}
                                    </p>
                                </div>
                            </div>

                            <input
                                type="range"
                                min="0"
                                max={maxAmount}
                                step="1"
                                value={amount}
                                onChange={handleSliderChange}
                                className="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-lime-400 focus:outline-none mt-4"
                            />

                            {/* Preset Buttons */}
                            <div className="grid grid-cols-4 gap-4 w-full mt-6">
                                {[25, 50, 75, 100].map((percentage) => (
                                    <button
                                        key={percentage}
                                        onClick={() => handlePresetClick(percentage)}
                                        className="bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
                                    >
                                        {percentage}%
                                    </button>
                                ))}
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <h2 className="text-xl font-semibold text-center text-gray-700 mb-6">
                            Confirm Payout
                        </h2>
                        <p className="text-center text-gray-600 mb-6">
                            You are about to receive a payout of{" "}
                            <span className="font-bold text-gray-800">
                                {getCurrencyFormat(
                                    amount,
                                    orgPaymentDetails.balance?.available?.[0]?.currency.toUpperCase(),
                                    true
                                )}
                            </span>{" "}
                            to your {orgPaymentDetails.external_acc?.type === "bank_account"
                                ? "bank account"
                                : orgPaymentDetails.external_acc?.type === "card"
                                    ? "card"
                                    : "account"} ending in{" "}
                            <span className="font-bold text-gray-800">
                                {orgPaymentDetails.external_acc?.last4 || "****"}.
                            </span>
                        </p>
                    </>
                );

            case 3:
                if (initiatePayout.status === "error") {
                    return (
                        <div className="flex flex-col items-center justify-center text-center">
                            <div className="mx-auto mb-8 rounded-full transition duration-500 ease-in-out h-16 w-16 flex items-center justify-center py-3 bg-red-600 text-white font-bold border border-red-600">
                                <span className="bg-red-600 text-white font-bold border border-red-600 text-3xl">
                                    &#10007;
                                </span>
                            </div>
                            <h2 className="text-3xl font-semibold text-center text-red-600 mb-6">
                                Payout Failed
                            </h2>
                            <p className="text-center text-gray-600">
                                Unfortunately, we couldn't process your payout. Please try again later or contact support for assistance.
                            </p>
                            <button
                                onClick={closeModal}
                                className="bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border border-slate-300 hover:bg-slate-700 hover:text-white mt-6"
                            >
                                Close
                            </button>
                        </div>
                    );
                }

                return (
                    <div className="flex flex-col items-center justify-center text-center">
                        <div className="mx-auto mb-8 rounded-full transition duration-500 ease-in-out h-16 w-16 flex items-center justify-center py-3 bg-[#529535] text-white font-bold border border-[#529535]">
                            <span className="bg-[#529535] text-white font-bold border border-[#529535] text-3xl">
                                &#10003;
                            </span>
                        </div>
                        <h2 className="text-3xl font-semibold text-center text-[#529535] mb-6">
                            Congratulations!
                        </h2>
                        <p className="text-center text-gray-600">
                            Your payout of{" "}
                            <span className="font-bold text-gray-800">
                                {getCurrencyFormat(
                                    amount,
                                    orgPaymentDetails.balance?.available?.[0]?.currency.toUpperCase(),
                                    true
                                )}
                            </span>{" "}
                            has been successfully initiated and should arrive in your bank account on file within the next 1-4 business days.
                        </p>
                        <button
                            onClick={closeModal}
                            className="bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border border-slate-300 hover:bg-slate-700 hover:text-white mt-6"
                        >
                            Close
                        </button>
                    </div>
                );


            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75">
            <div className="flex items-center justify-center min-h-screen px-4">
                <div className="bg-white rounded-lg shadow-xl sm:max-w-lg w-full transform transition-all">
                    <button
                        onClick={closeModal}
                        type="button"
                        className="absolute left-[90%] top-[3%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 border-none rounded-full text-sm p-1.5 z-30"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="px-6 py-4 border-b">
                        <h3 className="text-lg font-medium text-gray-900">
                            Make a Payout
                        </h3>
                    </div>

                    <div className="px-6 py-8">{renderContent()}</div>

                    {step !== 3 && (
                        <StepperControl
                            handleClick={handleNext}
                            currentIndex={step - 1}
                            isNextDisabled={step === 1 && amount < 1}
                            nextLabel={step === 2 ? "Confirm" : "Next"}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PayoutModal;
