import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';


import { requestOrganizationAdminInvitation } from '../store/actions/index';


const InviteUserModal = ({ onClose }) => {

    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [selectedRole, setSelectedRole] = useState('Super Admin');
    const [email, setEmail] = useState('');
    const [roleMappings, setRoleMappings] = useState({});


    const dispatch = useDispatch();

    const organizationAdminInvitation = useSelector((state) => state.organizerAdminInvitation.orgInvitation);
    const organizationAdminInvitationLoading = useSelector((state) => state.organizerAdminInvitation.loading);

    const organizationAdminRoles = useSelector((state) => state.organizerAdminRoles.orgRoles);

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);



    const submitOrderRefund = async () => {
        const accessToken = await getAccessTokenSilently();
        const roleId = roleMappings[selectedRole];
        const payload = {
            "role_id": roleId,
            "email": email
        }
        dispatch(requestOrganizationAdminInvitation(organizationDetails.organizationSettings.basicSettings.orgId, accessToken, payload));
    }

    const closeModal = () => {
        // dispatch(resetOrganizerRefundOrder())
        onClose()
    }

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Build role mappings when organizationAdminRoles changes
    useEffect(() => {
        if (organizationAdminRoles && organizationAdminRoles.length > 0) {
            const mappings = {};
            organizationAdminRoles.forEach((role) => {
                mappings[role.name] = role.role_id;
            });
            setRoleMappings(mappings);
        }
    }, [organizationAdminRoles]);


    // TODO: need to handle exception when refund is unsuccessful

    return (
        <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75">
            <div className="flex items-center justify-center min-h-screen px-4">

                {/* Modal content */}
                <div className="flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
                    <div className='flex flex-auto overflow-hidden'>
                        <button onClick={closeModal} type="button" className="absolute left-[90%] top-[3%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 border-none rounded-full text-sm p-1.5 z-30" data-modal-hide="extralarge-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>


                        {/* Left pane */}
                        <div className='flex-col w-full'>
                            {/* Modal header */}
                            <div className="flex items-center justify-start p-5 border-b rounded-t">
                                <div className='flex flex-col items-center'>
                                    <h3 className="text-xl font-medium text-gray-900">
                                        Invite people to your Organization
                                    </h3>
                                </div>
                            </div>

                            {
                                Object.keys(organizationAdminInvitation).length == 0 ?

                                    <>
                                        <div className='mt-5 sm:mx-auto sm:w-full sm:max-w-sm'>
                                            <p className='text-sm text-gray-500 font-normal'>
                                                Members will receive an email inviting them to sign up. If they already have an account, the should antomatically see the application
                                            </p>
                                        </div>


                                        <div className="my-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                            <div className="space-y-6" >
                                                <div>
                                                    <label for="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autocomplete="email"
                                                            value={email}
                                                            onChange={handleEmailChange}
                                                            required
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">Role</label>
                                                    <select
                                                        id="role"
                                                        className="block w-full mt-1 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        value={selectedRole}
                                                        onChange={handleRoleChange}
                                                    >
                                                        {/* <option value="Super Admin">Super Admin</option>
                                                        <option value="Member">Member</option> */}
                                                        {organizationAdminRoles.map((role) => (
                                                            <option key={role.role_id} value={role.name}>{role.name}</option>
                                                        ))}
                                                    </select>
                                                </div>


                                                <div>
                                                    <button
                                                        // type="submit"
                                                        className='flex bg-lime-500 justify-center w-full py-1.5 rounded-sm shadow-sm text-white hover:bg-lime-600 hover:cursor-pointer border-none text-sm font-semibold'
                                                        onClick={() => submitOrderRefund()}
                                                    >
                                                        {
                                                            organizationAdminInvitationLoading ?
                                                                <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                                </svg>
                                                                : <>Send Invite</>
                                                        }
                                                    </button>
                                                </div>
                                            </div>

                                        </div>


                                        <div className='mt-5 bg-gray-50 text-gray-500 block w-full py-2 px-4 text-justify text-xs font-medium'>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                            <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> See More</a>
                                        </div>
                                    </>

                                    :

                                    organizationAdminInvitation.status == 'success' ?
                                        <>
                                            <div className='vh-100 mt-16 mx-8'>
                                                <div className='flex flex-col w-[480px] left-0 top-0 m-auto mb-16'>
                                                    <div className='mx-auto mb-8 rounded-full transition duration-500 ease-in-out h-16 w-16 flex items-center justify-center py-3 bg-[#529535] text-white font-bold border border-[#529535]'>
                                                        <span className='bg-[#529535] text-white font-bold border border-[#529535] text-3xl'>
                                                            &#10003;
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <p className='font-bold text-[#003f6b] text-center'>
                                                            User invited to Organization.
                                                        </p>
                                                        <p className='font-semibold text-[#474b55] text-center'>
                                                            A User with this email has been added to your organization, they will have access to make changes within your org, to view all roles and permissions <br />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        :

                                        <></>

                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InviteUserModal